import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { hasKey } from '../misc/utils';

@Injectable()
export class JsonInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => { 
      if (event instanceof HttpResponse) {
        this.decodeJsonStrings(event.body);
      }
    }));
  }

  private decodeJsonStrings(object: Object) {
    if (!object || !(object instanceof Object)) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.decodeJsonStrings(item);
      }
    }
    
    for (const key of Object.keys(object)) {
      if (hasKey(object, key)) {
        const value = object[key];

        if (value instanceof Array) {
          for (const item of value) {
            this.decodeJsonStrings(item);
          }
        }
  
        if (value instanceof Object) {
          this.decodeJsonStrings(value);
        }
  
        if (typeof value === 'string' && value != null &&
          ((value as string).indexOf('\\') > -1 || (value as string).indexOf('\\u') > -1)) {
          const parsedVal = JSON.parse(`["${value}"]`);
          object[key] = parsedVal[0];
        }
      }      
    }
  }
}