import { Component, OnInit } from "@angular/core";
import { RoleStore, RoleStorageService } from "../services/role-storage.service";
import { MsalService } from "@azure/msal-angular";
import { isNullOrUndefined } from "../misc/utils";
import { Router } from "@angular/router";

@Component({
  selector: "app-role-dashboard",
  templateUrl: "./role-dashboard.component.html",
})
export class RoleDashboardComponent implements OnInit {

  roleStore: RoleStore;

  constructor(
    private roleStorage: RoleStorageService,
    private router: Router,
    private msalService: MsalService) {}

  ngOnInit(): void {

    if (!this.isLoggedIn()) {
      this.router.navigateByUrl('/landing-page');
    }

    this.roleStorage.fetchRoleStore().subscribe(store => {
      this.roleStore = store;
    });
  }

  isLoggedIn() {
    const user = this.msalService.instance.getActiveAccount();

    if(isNullOrUndefined(user)) {
      return false;
    }

    return user?.idTokenClaims?.roles.includes("CM_Admin");
  }

  updateChecked(index: number) {
    this.roleStorage.updateChecked(index);
  }

  onReset() {
    this.roleStorage.resetRoles();
  }

  onFetch() {
    this.roleStorage.fetchMSALRoles();
  }

  onSave() {
    this.roleStorage.saveRoleStore();
  }

  onCustom() {
    this.roleStorage.toggleCustom();
  }
}
