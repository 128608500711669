import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthHelperService } from '../shared/services/auth-helper.service';

type StyleType = 'unauthorized' | 'disabled' | 'hover';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html'
})

export class LandingPageComponent implements OnInit {
    mySVG: string;
    validAppPermissions = false;
    adminPermissions: boolean;
    processingPermissions: boolean;
    pqrPermissions: boolean;
    pqrIntlPermissions:boolean = false;
    isRevManagementUser: boolean = false;
    authInitialComplete = false;
    isUserAuthenticated = false;
    private readonly _destroying$ = new Subject<void>();

    constructor(
        private router: Router,
        private userService: UserService,
        private authHelperService: AuthHelperService,
        private msalBroadcastService: MsalBroadcastService,
        private authService: MsalService
    ) {
        this.validAppPermissions = false;
        this.adminPermissions = false;
        this.processingPermissions = false;
        this.pqrPermissions = false;
    }

    ngOnInit() {
        this.msalBroadcastService.inProgress$
            .pipe(filter(
                (status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$))
            .subscribe(() => {
                // give a beat before toggling the flag which will show the prompt to sign in,
                // to avoid flicker while silent token acquisition may still be playing out
                setTimeout(() => {
                    this.authInitialComplete = true;
                }, 500);
            });

        this.msalBroadcastService.msalSubject$
            .pipe(filter(
                (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
                takeUntil(this._destroying$))
            .subscribe((result: EventMessage) => {
                console.log("LOG IN LANDING")
                this.checkUserIsAuthenticated();
            });

        this.checkUserIsAuthenticated();

        // SVG for tiles on landing page
        this.updateSVG()
    }

    link = "../"

    updateSVG() {
        this.mySVG = `
    <svg class="svg1" viewBox="0 0 1280 720" width="2440" height="1200" ${this.tileStyle('unauthorized', this.validAppPermissions)}>

    <!-- bottom right -->
    <g transform="matrix(1.1,0,0,1.1,-128,-52)">
        <defs>
            <clipPath id="hexView1">
                <path class="shp0"
                    d="M 1783.5 1340.5 L 1449 1343.7 L 1279 1055.7 L 1443.4 764.5 L 1777.8 761.2 L 1947.8 1049.2 L 1783.4 1340.5 Z"
                    id="Shape1" transform="matrix(0.4,0,0,0.4,369.3,130.3)" />
            </clipPath>
        </defs>
        <image x="880" y="400" width="450" height="300" href="assets/img/approval.jpg" clip-path="url(#hexView1)" />
    </g>
    <g transform="matrix(1.1,0,0,1.1,-30.6,-28)" fill="rgb(91, 160, 77)" ${this.tileStyle('hover', this.adminPermissions)}
        id="adminFunctions">
        <path class="shp0"
            d="M -551.3 689 L -885.8 692.2 L -1055.8 404.2 L -891.4 113 L -556.9 109.7 L -386.9 397.7 L -551.4 689 Z"
            id="Shape2" transform="matrix(0.4,0,0,0.4,369.3,130.3)" />
        <text x="42" y="278.2" fill="white" font-size="28" ${this.tileStyle('disabled', this.adminPermissions)}>Admin</text>
        <text x="27" y="313.2" fill="white" font-size="28" ${this.tileStyle('disabled', this.adminPermissions)}>Functions</text>
    </g>
        <!-- bottom left -->
    <g transform="matrix(1.1,0,0,1.1,-31,-52.6)">
        <defs>
            <clipPath id="hexView2">
                <path class="shp0"
                    d="M -540.8 1363.6 L -875.3 1366.8 L -1045.3 1078.8 L -880.9 787.6 L -546.5 784.3 L -376.5 1072.3 L -540.9 1363.6 Z"
                    id="Shape3" transform="matrix(0.4,0,0,0.4,369.3,130.3)" />
            </clipPath>
        </defs>
        <image href="assets/img/marketing.jpg" x="-70" y="360" width="350" height="400" clip-path="url(#hexView2)" />
    </g>
    <!-- top left -->
    <g transform="matrix(1.1,0,0,1.1,-54.7,-15.5)">
        <defs>
            <clipPath id="hexView3">
                <path class="shp0"
                    d="M 28.8 351.1 L -305.7 354.3 L -475.7 66.3 L -311.3 -224.9 L 23.1 -228.2 L 193.1 59.8 L 28.7 351.1 Z"
                    id="Shape4" transform="matrix(0.4,0,0,0.4,369.3,130.3)" />
            </clipPath>
        </defs>
        <image x="0" y="10" width="450" height="300" href="assets/img/US.png" clip-path="url(#hexView3)" />
    </g>
    <g transform="matrix(1.1,0,0,1.1,-54.9,-40.1)" ${this.tileStyle('hover', this.pqrPermissions)} fill="rgb(237, 191, 85)"
        id="pqrDashboard">
        <path class="shp0"
            d="M 33.5 1024 L -301 1027.2 L -471 739.1 L -306.6 447.9 L 27.8 444.6 L 197.8 732.6 L 33.4 1024 Z"
            id="Shape5" transform="matrix(0.4,0,0,0.4,369.3,130.3)" />
        <text x="283.4" y="414.2" fill="white" font-size="28" ${this.tileStyle('disabled', this.pqrPermissions)}>PQR</text>
        <text x="255.4" y="449.2" fill="white" font-size="28" ${this.tileStyle('disabled', this.pqrPermissions)}>Domestic</text>
    </g>
    <g transform="matrix(1.1,0,0,1.1,-79.2,-27.7)" ${this.tileStyle('hover', this.validAppPermissions)} fill="rgb(28, 66, 105)"
    id="customerDashboard">
        <path class="shp0"
            d="M 612.1 680 L 277.6 683.3 L 107.6 395.3 L 272.1 104.1 L 606.4 100.7 L 776.4 388.8 L 612.1 680 Z"
            id="Shape 6" transform="matrix(0.4,0,0,0.4,369.3,130.3)" />
        <text x="486.1" y="274.4" fill="white" font-size="28" ${this.tileStyle('disabled', this.validAppPermissions)}>Customer</text>
        <text x="471.1" y="309.4" fill="white" font-size="28" ${this.tileStyle('disabled', this.validAppPermissions)}>Dashboard</text>
    </g>
    <!-- mid bottom left -->
    <g transform="matrix(1.1,0,0,1.1,-79.2,-52.1)">
        <defs>
            <clipPath id="hexView6">
                <path class="shp0"
                    d="M 618.7 1348.7 L 284.2 1351.8 L 114.2 1063.8 L 278.6 772.6 L 613 769.4 L 783 1057.3 L 618.6 1348.7 Z"
                    id="Shape7" transform="matrix(0.4,0,0,0.4,369.3,130.3)" />
            </clipPath>
        </defs>
        <image x="350" y="350" width="350" height="400" href="assets/img/upswing.png" clip-path="url(#hexView6)" />
    </g>
    <g transform="matrix(1.1,0,0,1.1,-103.6,-39.9)" fill="rgb(238, 132, 52)" ${this.tileStyle('hover', this.pqrIntlPermissions)}
    id="international">
        <path class="shp0"
            d="M 1198.6 1013.4 L 864.1 1016.6 L 694.1 728.6 L 858.5 437.4 L 1192.9 434.1 L 1362.9 722.1 L 1198.5 1013.4 Z"
            id="Shape8" transform="matrix(0.4,0,0,0.4,369.3,130.3)" />
        <text x="749.4" y="414.2" fill="white" font-size="28" ${this.tileStyle('disabled', this.pqrIntlPermissions)}>PQR</text>
        <text x="699.4" y="449.2" fill="white" font-size="28" ${this.tileStyle('disabled', this.pqrIntlPermissions)}>International</text>
    </g>

        <linearGradient id="ltd0h3pn" />
<!-- mid top right -->
    <g transform="matrix(1.1,0,0,1.1,-103.5,-15.2)">
        <defs>
            <clipPath id="hexView4">
                <path class="shp0"
                    d="M 1196.3 343.3 L 861.8 346.5 L 691.8 58.6 L 856.2 -232.7 L 1190.6 -235.9 L 1360.6 52.1 L 1196.2 343.3 Z"
                    id="Shape8" transform="matrix(0.4,0,0,0.4,369.3,130.3)"/>
            </clipPath>
        </defs>
        <image href="assets/img/earth.jpg" x="560" y="-170" width="450" height="650" clip-path="url(#hexView4)" />
    </g>
    <g transform="matrix(1.1,0,0,1.1,-127.9,-27.3)" fill="rgb(215, 62, 100)" ${this.tileStyle('hover', this.validAppPermissions)}
    id="approvalNotification">
        <path class="shp0"
            d="M 1774.6 675.7 L 1440.2 679 L 1270.2 391 L 1434.6 99.8 L 1769 96.5 L 1939 384.5 L 1774.6 675.7 Z"
            id="Shape8" transform="matrix(0.4,0,0,0.4,369.3,130.3)"/>
        <text x="957" y="278.2" fill="white" font-size="28" ${this.tileStyle('disabled', this.validAppPermissions)}>Approval</text>
        <text x="942" y="313.2" fill="white" font-size="28" ${this.tileStyle('disabled', this.validAppPermissions)}>Dashboard</text>
    </g>
    <g fill="rgb(41, 100, 142)" ${this.tileStyle('hover', this.processingPermissions)} transform="matrix(1.1,0,0,1.1,-127.9,-27.3)"
    id="processing-configuration">
        <path class="shp0"
            d="M 2304.1 973 L 1969.7 976.3 L 1799.7 688.3 L 1964.1 397.1 L 2298.5 393.8 L 2468.5 681.8 L 2304.1 973 Z"
            id="Shape8" transform="matrix(0.4,0,0,0.4,369.3,130.3)"/>
        <text fill="white" font-size="28" x="1146.3" y="414.6" ${this.tileStyle('disabled', this.processingPermissions)}>Configuration</text>
    </g>
    <!-- top right -->
    <g transform="matrix(1.1,0,0,1.1,-103.5,-15.2)">
        <defs>
            <clipPath id="hexView5">
                <path class="shp0"
                    d="M 2244 340.6 L 1909.5 343.8 L 1739.5 55.9 L 1903.9 -235.4 L 2238.3 -238.6 L 2408.3 49.4 L 2243.9 340.6 Z"
                    id="Shape8" transform="matrix(0.4,0,0,0.4,369.3,130.3)"/>
            </clipPath>
        </defs>
        <image href="assets/img/workStation.png" x="1024" y="0" width="350" height="300" clip-path="url(#hexView5)" />
    </g>
    </svg>
  `;
    }

    tileStyle(styleType: StyleType, permissions: boolean): string {
        switch (styleType) {
            case 'unauthorized':
                return permissions ? '' : 'class="opacity-75-no-pointer"';
            case 'disabled':
                return permissions ? '' : 'class="opacity-50"';
            case 'hover':
                return permissions ? 'class="ui-tile"' : '';
            default:
                return '';
        }
    }

    checkUserIsAuthenticated() {
        if (this.authService.instance.getAllAccounts().length > 0) {
            this.validAppPermissions = this.userIsAppValid();
            this.adminPermissions = this.userService.userIsCMAdmin() || this.userService.userHasOnlyITRole();
            this.processingPermissions = this.userService.userIsIndustrialEngineer() ||
                this.userService.userIsTransportation() ||
                this.userService.userIsNationalTransportation() ||
                this.userService.userIsOperations() ||
                this.userService.userIsOperationsSupervisor() ||
                this.userService.userIsOpsManager() ||
                this.userService.userIsRegional() ||
                this.userService.userIsIT() ||
                this.userService.userIsInternational() ||
                this.userService.userIsTransportationUser();

            let globalRatesRoleConstraint = this.userService.userIsFinanceRevAccounting();
            if (globalRatesRoleConstraint) {
                this.userService.userHasSubrole('Revenue Management').then((result) => {
                    this.isRevManagementUser = result;
                    this.processingPermissions = this.processingPermissions || this.isRevManagementUser;
                    this.updateSVG();
                });
            }

            this.pqrPermissions = this.userService.userIsFinanceRevAccounting() || this.userService.userIsIT()
                this.userService.userIsCMAccountExec() ||
                this.userService.userIsCMSalesSupport();
            this.pqrIntlPermissions = this.userService.userIsInternational();
            this.isUserAuthenticated = true;
            this.authInitialComplete = true;
            this.updateSVG();
        }
    }

    // Routes for navitgating to components from tiles
    navigateModule(e) {
        if (e.target.closest('#customerDashboard') || e.target.closest('#dashboard')) {
            sessionStorage.setItem("tileName", "Customer");
            sessionStorage.setItem("homeNavigation", "Customer");
            this.router.navigate(['/customer']);
        } else if (e.target.closest('#adminFunctions') && this.adminPermissions) {
            this.router.navigate(['/admin']);
        } else if (e.target.closest('#approvalNotification') || e.target.closest('#approval')) {
            sessionStorage.setItem("tileName", "Customer");
            sessionStorage.setItem("homeNavigation", "Approval");
            this.router.navigate(['/approval-notification']);
        } else if ((e.target.closest('#processing-configuration') || e.target.closest('#processing')) && this.processingPermissions) {
            sessionStorage.setItem("tileName", "Configuration");
            if(this.isRevManagementUser) {
                this.router.navigate(['/rpf/global-special-service-rates']);
            }
            else if(this.userService.userIsInternational()) {
                this.router.navigate(['/rpf/international-route-table']);
            }
            else {
                this.router.navigate(['/rpf']);              
            }            
        } else if (e.target.closest('#pqrDashboard') && this.pqrPermissions) {
            this.router.navigate(['/pqr']);
        } 
    }

    userIsAppValid = (): boolean => {   
        return this.userService.userhasValidCMApplicationRole();
    }

    login() {
        this.authHelperService.login();
    }
}