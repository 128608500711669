import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalNotificationGuard  {
  constructor(private userService: UserService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if(!environment.routeGuardsEnabled) {
        return true;
      }

      const allowedRoles = new Set<boolean>();

      allowedRoles.add(this.userService.userIsCMApprover());
      allowedRoles.add(this.userService.userIsNationalTransportation());
      allowedRoles.add(this.userService.userIsRegional());
      allowedRoles.add(this.userService.userIsOpsManager());
      allowedRoles.add(this.userService.userIsTransportation());
      allowedRoles.add(this.userService.userIsCorpBilling());
      allowedRoles.add(this.userService.userIsCMSalesSupport());
      allowedRoles.add(this.userService.userIsIT());
      allowedRoles.add(this.userService.userIsTransportationUser());


      const userInAllowedRoles = allowedRoles.has(true);

      if(!userInAllowedRoles) {
        this.userService.userAccessDenied();
      }

      return userInAllowedRoles;
  }

}
