import { Injectable } from '@angular/core';
import { Store } from './store';
import { CmState, AccessError, ParentChildContext, TargetEntity, TargetCustomer, CurrentUser, ApprovalDashSearchCriteria  } from '../models/cm-state';
import { SearchRecordsRequest } from '../models/search.model';
import { FieldMaster, FieldMapItem } from '../models/field-master';
import { BusinessUnit } from '../models/business-unit.model';
import { ViewApprovalUI } from '../models/customer-approval.model';

type Severities = 'success' | 'info' | 'warn' | 'error';

// https://jurebajt.com/state-management-in-angular-with-observable-store-services/
@Injectable()
export class CmAppStore extends Store<CmState> {
  constructor() {
    super(new CmState());
  }

  updateTargetUser(user: TargetEntity): void {
    this.setState({ ...this.state, targetUser: user });
    // console.log('targetUser STATE AFTER', this.state.targetUser);
  }

  updateCurrentUser(user: CurrentUser): void {
    this.setState({ ...this.state, currentUser: user });
    // console.log('currentUser STATE AFTER', this.state.currentUser);
  }

  updateTargetCustomer(customer: TargetCustomer): void {
    this.setState({ ...this.state, targetCustomer: customer });
    // console.log('targetCustomer STATE AFTER', this.state.targetCustomer);
  }

  updateSearchFilters(filter: ApprovalDashSearchCriteria): void {
    this.setState({ ...this.state, searchCriteria: filter });
    // console.log('targetCustomer STATE AFTER', this.state.targetCustomer);
  }

  updateTargetRPF(rpf: TargetEntity): void {
    this.setState({ ...this.state, targetRPF: rpf });
    // console.log('targetRPF STATE AFTER', this.state.targetRPF);
  }

  updateActiveCustomerSearch(searchRequest: SearchRecordsRequest): void {
    this.setState({ ...this.state, activeCustomerSearch: searchRequest });
    // console.log('activeCustomerSearch STATE AFTER', this.state.activeCustomerSearch);
  }

  updateAccessError(error: AccessError): void {
    this.setState({ ...this.state, accessError: error });
    // console.log('accessError STATE AFTER', this.state.accessError);
  }

  showLoadIndicator(): void {
    this.setState({ ...this.state, loadIndicatorState: { show: true }});
    // console.log('showLoadIndicator');
  }
  clearLoadIndicator(): void {
    this.setState({ ...this.state, loadIndicatorState: { show: false }});
  }

  showSuccessMessage(showMessage: string): void {
    this.setState({ ...this.state, successMessageState: { show: true, message: showMessage }});
    // console.log('showSuccessMessage');
  }
  clearSuccessMessage(): void {
    this.setState({ ...this.state, successMessageState: { show: false, message: '' }});
    // console.log('clearSuccessMessage');
  }

  updateParentChildContext(pcContext: ParentChildContext): void {
    this.setState({ ...this.state, parentChildContext: pcContext });
    // console.log('parentChildContext STATE AFTER', this.state.parentChildContext);
  }
  resetParentChildContext(): void {
    this.setState({ ...this.state, parentChildContext: null });
    // console.log('parentChildContext STATE AFTER', this.state.parentChildContext);
  }

  updateFieldMaster(master: FieldMaster[]): void {
    this.setState({ ...this.state, fieldMaster: master });
    // console.log('updateFieldMaster STATE AFTER', this.state.fieldMaster);
  }

  updateBusinessUnits(units: BusinessUnit[]): void {
    this.setState({ ...this.state, businessUnits: units });
    // console.log('updateBusinessUnits STATE AFTER', this.state.businessUnits);
  }

  updateComponentFieldMap(fieldMap: FieldMapItem[]): void {
    this.setState({ ...this.state, componentFieldMap: fieldMap });
    // console.log('updateComponentFieldMap STATE AFTER', this.state.componentFieldMap);
  }

  updateSidenavCollapsed(collapsed: boolean): void {
    this.setState({ ...this.state, sidenavCollapsed: collapsed });
    // console.log('updateSidenavCollapsed STATE AFTER', this.state.sidenavCollapsed);
  }
}
