import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PQRGuard  {
  constructor(private userService: UserService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (environment.routeGuardsEnabled) {
      //- Access should be denied if user have Collections role.
      //- IT user should have read access
      const readOnlyAccess = this.userService.userIsIT();
      const roleConstraintMet = (this.userService.userIsFinanceRevAccounting() || this.userService.userIsIT() ||
                                this.userService.userIsCMAccountExec() || readOnlyAccess) && !(this.userService.userIsCollections());
      if (!roleConstraintMet) {
        this.userService.userAccessDenied();
      }
      return roleConstraintMet;
    } else {
      return true;
    }
  }
}
