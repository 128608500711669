import { Component, OnInit, OnDestroy } from '@angular/core';
import { CmAppStore } from '../services/cm-app-store';
import { map, distinctUntilChanged, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-load-indicator',
  templateUrl: './load-indicator.component.html'
})
export class LoadIndicatorComponent implements OnInit, OnDestroy {
  // TODO - support an indicator-only mode that does not display the blocking mask beneath it
  displayIndicator = false;
  displayMask = false;
  observables: any = {};

  constructor(private store: CmAppStore) { }

  ngOnInit() {
    this.observables.onLoaderState$ = this.store.state$
      .pipe(
        map(state => state.loadIndicatorState),
        distinctUntilChanged()
      )
      .subscribe(
        loadIndicatorState => {
          if (loadIndicatorState === undefined) {
            return;
          }
          // console.log('LoadIndicatorComponent onLoaderState$ loadIndicatorState: ', loadIndicatorState);
          // TODO - support an indicator-only mode that does not display the blocking mask beneath it
          this.displayMask = loadIndicatorState.show;
        },
        error => {},
        () => {}
      );
  }

  ngOnDestroy(): void {
    if (this.observables.onLoaderState$ !== undefined) {
      this.observables.onLoaderState$.unsubscribe();
      this.observables.onLoaderState$ = undefined;
    }
  }
}
