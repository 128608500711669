import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CmAppStore } from '../services/cm-app-store';
import { distinctUntilChanged, map, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html'
})
export class ToastMessageComponent implements OnInit, OnDestroy {
  observables: any = {};
  public success = new Subject<string>();
  public successMessage: string;

  constructor(private store: CmAppStore) { }

  ngOnInit() {
    this.success.subscribe(message => this.successMessage = message);
    this.success.pipe(
      debounceTime(3000)
    ).subscribe(() => {
      this.successMessage = null;
      this.store.clearSuccessMessage();
    });

    this.observables.onLoaderState$ = this.store.state$
      .pipe(
        map(state => state.successMessageState),
        distinctUntilChanged()
      )
      .subscribe(
        successMessageState => {
          if (successMessageState === undefined) {
            return;
          }

          if (successMessageState.show === true) {
            this.successMessage = successMessageState.message;
            this.success.next(this.successMessage);
          }

        },
        error => console.log('error: ', error),
        () => { }
      );
  }

  closeToast() {
    this.store.clearSuccessMessage();
  }

  ngOnDestroy(): void {
    if (this.observables.onLoaderState$ !== undefined) {
      this.observables.onLoaderState$.unsubscribe();
      this.observables.onLoaderState$ = undefined;
    }
  }
}
