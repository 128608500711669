/* values below must match db Roles table RoleDesc - REVISIT if an explicit lookup API call is preferable to this front-end enum mirror */
export enum UserSubRoleType {
    ['Revenue Management'] = 1,
    ['F&A'] = 2,
    TSA = 3,
    ['International Super User'] = 4,
    ['International Finance and Set Up'] = 5,
    ['International Transportation'] = 6,
    ['International Sales'] = 7
}

// corresponds to back-end UserRolesEnum
export enum UserRolesEnum {
    User = 'CM_User',
    AccountExec = 'CM_Account_Exec',
    Admin = 'CM_Admin',
    Approver = 'CM_Approver',
    SalesSupport = 'CM_Sales_Support',
    Finance = 'CM_Finance_Accouting_Revenue',
    OpsManager = 'CM_Ops_Mgmt',
    OpsUser = 'CM_Ops_User',
    OpsSupervisor = 'Operations_Supervisor',
    Regional = 'CM_Regional',
    CorpBilling = 'CM_Corp_Billing',
    RpfBilling = 'CM_RPF_Billing',
    Transportation = 'CM_Transportation',
    NationalTrans = 'CM_National_Trans',
    IndustrialEngineer = 'CM_Industrial_Engineer',
    PostalAffairs = 'CM_Postal',
    IT = 'MI-IT',
    APIConsumerApps = 'CM_API_ConsumerApps',
    TSA = 'TSA',
    International = 'International_User',
    Collections = 'Collections',
    InternationalSuperUser = 'International Super User', //International sub-role
    InternationalFinanceAndSetUp = 'International Finance and Set Up', //International sub-role
    InternationalTransportation = 'International Transportation', //International sub-role
    InternationalSales = 'International Sales', //International sub-role
    TransportationUser = 'Transportation_User'
}
