import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
// import { ConsoleLogService } from './console-log.service';

@Injectable({
  providedIn: 'root'
})

/*
for implementation approach reference see:
https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1403
*/
export class AppConfigService {
  private appConfig: any;
  private http: HttpClient;

  constructor(
    private readonly httpHandler: HttpBackend,
    // private consoleService: ConsoleLogService
  ) {
    this.http = new HttpClient(httpHandler);
  }

  loadAppConfig(configUrl: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http.get(configUrl)
        .pipe(map(res => res))
        .subscribe(data => {
          this.appConfig = data;
          resolve(true);
        },
        (error) => {
          reject(error);
        });
    });
  }

  appConfigAvailable():boolean {
    return this.appConfig != null;
  }

  get(key?: string | Array<string>): any {
    // this.consoleService.log('AppConfigService get() appConfig:',  this.appConfig);
    if (!key || (Array.isArray(key) && !key[0])) {
      return this.appConfig;
    }

    if (!Array.isArray(key)) {
      key = key.split('.');
    }

    const result = key.reduce((acc: any, current: string) => acc && acc[current], this.appConfig);
    return result;
  }
}
