import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlphaLinkbarComponent } from './alpha-linkbar/alpha-linkbar.component';
import { ApprovalGridComponent } from './approval/approval-grid/approval-grid.component';
import { ApprovalIndicatorComponent } from './approval/approval-indicator/approval-indicator.component';
import { CustomerNameHeaderComponent } from './customer-name-header/customer-name-header.component';
import { DeleteComponent } from './modals/delete/delete.component';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { FnPipe } from './pipes/fn-transform.pipe';
import { LoadIndicatorComponent } from './load-indicator/load-indicator.component';
import { ModalDialogComponent } from './modals/dialog/modal-dialog.component';
import { NavComponent } from './nav/nav.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { SaveSubmitComponent } from './save-submit/save-submit.component';
import { SortableHeaderDirective } from './directives/sortable-header.directive';
import { ToastMessageComponent } from './toast-message/toast-message.component';
import { DocViewerModalComponent } from './modals/doc-viewer-modal/doc-viewer-modal.component';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { AppDatePipe } from './pipes/custom-date.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { FnFormatValuePipe } from './pipes/fn-format-value.pipe';
import { TwoDigitDecimaNumberDirective } from './directives/two-digit-decimal-number.directive';
import { ToggleComponent } from './toggle/toggle.component';
import { EditableIndicatorComponent } from './approval/editable-indicator/editable-indicator.component';
import { RoleDashboardComponent } from './role-dashboard/role-dashboard.component';
import { CustomerContractsComponent } from './customer-contracts/customer-contracts.component';
import { CustomerSidenavComponent } from 'src/app/customer/customer-sidenav/customer-sidenav.component';

@NgModule({
    declarations: [
        AlphaLinkbarComponent,
        ApprovalGridComponent,
        ApprovalIndicatorComponent,
        CustomerNameHeaderComponent,
        DeleteComponent,
        DocViewerModalComponent,
        ErrorModalComponent,
        FnPipe,
        AppDatePipe,
        SafeHtmlPipe,
        LoadIndicatorComponent,
        ModalDialogComponent,
        NavComponent,
        NotificationSettingsComponent,
        PaginatorComponent,
        SaveSubmitComponent,
        SortableHeaderDirective,
        ToastMessageComponent,
        MultiSelectDropdownComponent,
        ClickOutsideDirective,
        FnFormatValuePipe,
        TwoDigitDecimaNumberDirective,
        ToggleComponent,
        EditableIndicatorComponent,
        RoleDashboardComponent,
        CustomerContractsComponent,
        CustomerSidenavComponent
        ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        RouterModule
    ],
    exports: [
        AlphaLinkbarComponent,
        ApprovalGridComponent,
        ApprovalIndicatorComponent,
        EditableIndicatorComponent,
        CustomerNameHeaderComponent,
        MultiSelectDropdownComponent,
        FnPipe,
        AppDatePipe,
        SafeHtmlPipe,
        FnFormatValuePipe,
        LoadIndicatorComponent,
        ToastMessageComponent,
        NavComponent,
        NotificationSettingsComponent,
        PaginatorComponent,
        SaveSubmitComponent,
        SortableHeaderDirective,
        ClickOutsideDirective,
        TwoDigitDecimaNumberDirective,
        ToggleComponent,
        RoleDashboardComponent,
        CustomerContractsComponent,
        CustomerSidenavComponent
    ]
})

export class SharedModule { }