<ng-template #SignIn>
    <button (click)="login()" class="btn btn-custom-sign-in">Sign In</button>
</ng-template>

<div id="cm-navbar-styles">
    <div class="app-header sticky">
        <nav class="navbar navbar-expand-lg navbar-dark">
            <a class="navbar-brand" [class.nav-disabled]="router.url === routeForbidden"
                [routerLink]="['/landing-page']"><img id="ups-logo" class="beside" src="assets/img/ups.png">
                <h6 class="beside">MI <br>Config</h6>
            </a>

            <div id="right-nav1" class="showMobile">
                <li id="sb-col1" *ngIf="showHome && homeRoute != null">
                    <a class="right-nav-element" [routerLink]="homeRoute"><i class="fas fa-home"></i></a>
                </li>
                <li *ngIf="showHome" class="no-home"></li>

                <li id="si-col1">
                    <div *ngIf="userIsAppValid(); else SignIn" ngbDropdown class="d-inline-block front">
                        <a class="nav-link right-nav-element" id="SignOutIcon" ngbDropdownToggle><i
                                class="fas fa-user"></i></a>
                        <div ngbDropdownMenu aria-labelledby="SignOutIcon">
                            <button (click)="logout()" class="dropdown-item">Sign Out</button>
                            <button *ngIf="!errorRoute" (click)="notifications()"
                                class="dropdown-item">Settings</button>
                        </div>

                    </div>
                </li>

                <li id="toggleNav" *ngIf="showTabs">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
                        aria-controls="collapseExample" aria-expanded="false" [attr.aria-expanded]="!isCollapsed"
                        (click)="isCollapsed = !isCollapsed">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </li>
                <li *ngIf="showTabs" class="no-toggle"></li>

            </div>
            <div class="collapse navbar-collapse" id="collapseExample" [ngbCollapse]="!isCollapsed">
                <div class="navbar-nav">
                    <ng-container *ngIf="showTabs">
                        <li *ngFor="let navItem of navTabs" class="navbarlink space"
                            [class.active]="activeTab === navItem.key">
                            <a class="nav-item nav-link" [class.disabled]="!navItem.enabled"
                                (click)="navigateTab(navItem)">
                                {{navItem.label}}
                            </a>
                        </li>
                    </ng-container>
                </div>
            </div>

            <div id="right-nav" class="showFull">
                <li id="sb-col1" class="mt-1">
                    <a class="right-nav-element right-nav-a">{{currentUserName}}</a>
                </li>

                <li id="sb-col1" *ngIf="showHome && homeRoute != null">
                    <a [routerLink]="homeRoute" class="right-nav-element"><i class="fas fa-home"></i></a>
                </li>
                <li *ngIf="showHome" class="no-home"></li>

                <li id="si-col1">
                    <div *ngIf="userIsAppValid(); else SignIn" ngbDropdown class="d-inline-block front">
                        <a id="SignOutIcon" class="nav-link right-nav-element" ngbDropdownToggle><i
                                class="fas fa-user"></i></a>
                        <div ngbDropdownMenu aria-labelledby="SignOutIcon">
                            <button (click)="logout()" class="dropdown-item">Sign Out</button>
                            <button *ngIf="!errorRoute" (click)="notifications()"
                                class="dropdown-item">Settings</button>
                        </div>
                    </div>
                </li>

                <li id="toggleNav" *ngIf="showTabs">
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"
                        aria-controls="collapseExample" aria-expanded="false" [attr.aria-expanded]="!isCollapsed"
                        (click)="isCollapsed = !isCollapsed">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </li>
                <li *ngIf="showTabs" class="no-toggle"></li>
            </div>
        </nav>
        <div *ngIf="pcContext.active" class="nav-row-cnt ribbon">
            <span class="pc-currently-viewing">Currently viewing: </span>
            <span *ngIf="pcContext.isParent" class="pc-label-style">Parent <span
                    class="pc-account">Account</span></span>
            <span *ngIf="pcContext.hasParent" class="pc-label-style">Child <span
                    class="pc-account">Account</span></span>
            <span *ngIf="customerProfile.parentName?.length"> of
                <a [routerLink]="[]" (click)="navToParentCustomerProfile()"
                    class="pc-label-style">{{customerProfile.parentName}}</a>
            </span>
            <span *ngIf="parentChildEnabled">
                - see <a [routerLink]="['/customer', pcContext.parentId, 'parent-dashboard']">Parent Dash<span
                        class="pc-board">board</span><i class="hierarchy-icon fa fa-sitemap"></i></a>
            </span>
        </div>
    </div>
</div>