import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MsalService } from "@azure/msal-angular";
import ROLES from "../../../assets/roles";
import { isNullOrUndefined } from "../misc/utils";

export interface Role {
  name: string;
  checked: boolean;
}

export interface RoleStore {
  roles: Role[],
  isCustom: boolean,
}

@Injectable({
  providedIn: "root",
})
export class RoleStorageService {
  private key: string = 'LOWER_ENV_KEY' as const;
  private roleStore: BehaviorSubject<RoleStore>;

  constructor(private msalService: MsalService) {
    this.roleStore = new BehaviorSubject(this.fetchSessionRoles());
  }

  initRoles(ROLES: string[]): RoleStore {
    const roles = ROLES.map((r) => {
      return {
        name: r,
        checked: false,
      };
    });

    return {
      roles,
      isCustom: false
    }
  };

  toggleCustom() {
    const role = this.roleStore.getValue();
    const nextRole = {
      ...role,
      isCustom: !role.isCustom
    }
    this.roleStore.next(nextRole);
  }

  resetRoles() {
    this.roleStore.next(this.initRoles(ROLES));
    this.saveRoleStore();
  }

  updateChecked(index: number) {
    const roleStore = this.roleStore.getValue();
    const roles = roleStore.roles.map((r, i) => {
      const updateChecked = i === index ? !r.checked : r.checked;
      return {
        ...r,
        checked: updateChecked,
      };
    });

    this.roleStore.next({
      ...roleStore,
      roles,
    });
  }

  fetchSessionRoles(): RoleStore {
    const sessionRoles = sessionStorage.getItem(this.key);
    if(isNullOrUndefined(sessionRoles)) {
      const msalRoles = this.initRoles(ROLES);
      this.setSessionRoles(msalRoles);
      return msalRoles;
    } else {
      return JSON.parse(sessionRoles);
    }
  }

  setSessionRoles(roleStore: RoleStore) {
    sessionStorage.setItem(this.key, JSON.stringify(roleStore));
  }

  saveRoleStore() {
    this.setSessionRoles(this.roleStore.getValue());
  }

  fetchMSALRoles() {
    const userAccount = this.msalService.instance.getActiveAccount();

    if(isNullOrUndefined(userAccount)) {
      return this.initRoles(ROLES);
    }

    const userRoles = userAccount?.idTokenClaims?.roles;

    const initRoles = this.initRoles(ROLES);
    const roles = initRoles.roles.map(r => {
      const fillChecked = userRoles.includes(r.name);
      return {
        ...r,
        checked: fillChecked,
      }
    }); 

    this.roleStore.next({
      ...initRoles,
      roles,
    });
  }

  fetchRoleStore() {
    return this.roleStore.asObservable();
  }
}
