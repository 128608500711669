import { Component, OnInit } from '@angular/core';
import { CmAppStore } from 'src/app/shared/services/cm-app-store';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-not-permitted',
  templateUrl: './not-permitted.component.html'
})
export class NotPermittedComponent implements OnInit {
  signoutErrorMessage = 'You will be signed out momentarily.';
  // eslint-disable-next-line max-len
  messageBody: string;
  signoutWaitTimeMs = 9800; // sign them out in 10 seconds (leave a fraction-of-second buffer for execution of MSAL logout)

  constructor(
    public store: CmAppStore,
    private msalService: MsalService
  ) { }

  ngOnInit() {
    const storedAccessError = this.store.state.accessError;
    if (storedAccessError != null) {
      const useMessage = storedAccessError.messageHtml != null ? storedAccessError.messageHtml : storedAccessError.message;
      this.messageBody = `${useMessage}<br>${this.signoutErrorMessage}`;
    } else {
      // eslint-disable-next-line max-len
      this.messageBody = `The system does not recognize you, or you lack the necessary role(s) to access this application.<br>${this.signoutErrorMessage}`;
    }

    // console.log('this.signoutWaitTimeMs: ', this.signoutWaitTimeMs);
    setTimeout(() => {
      sessionStorage.clear();
      this.msalService.logout();
    }, this.signoutWaitTimeMs);
  }

}
