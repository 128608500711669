<section id="role-dashboard" *ngIf="isLoggedIn()">
  <span class="warning">
    WARNING TEXT
  </span>
  <h1>Role Dashboard</h1>
  <div class="header">
    <div class="cstm" [attr.data-checked]="roleStore.isCustom">
      <span (click)="onCustom()">Use Custom Roles</span>
    </div>
    <div class="save-reset">
      <button class="gold" (click)="onFetch()">Fetch Roles</button>
      <button class="gold" (click)="onReset()">Reset</button>
      <button class="gold" (click)="onSave()">Save</button>
    </div>
  </div>
  <div class="roles">
    <div
      class="role"
      [attr.data-checked]="role.checked"
      *ngFor="let role of roleStore.roles; index as i"
      (click)="updateChecked(i)"
    >
      <span>{{ role.name }}</span>
    </div>
  </div>
</section>
