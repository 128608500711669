import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, distinctUntilChanged } from 'rxjs/operators';
import { CustomerProfile, ParentCustomer, ChildCustomer, CustomerApiKeyRequest } from 'src/app/shared/models/customer-profile.model';
import { CustomerAccountTypes, CustomerAccount } from 'src/app/shared/models/customer-account.model';
import { AppInsightsService } from './appinsights.service';
import { AppConfigService } from 'src/app/app-config.service';
import { ErrorHandlerService } from './error-handler.service';
import { CmAppStore } from './cm-app-store';
import { ShipperType, ShipperTypeCategory } from '../enums/shipper-type.enum';
import { TargetCustomer } from '../models/cm-state';
import { ComponentBase } from '../models/component-base';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private url: string;
  private customerApiKeyUrl: string;

  constructor(
    appConfigService: AppConfigService,
    private http: HttpClient,
    private appInsights: AppInsightsService,
    private errorHandler: ErrorHandlerService,
    private store: CmAppStore
  ) {
    const baseUrl = appConfigService.get('apiUrl');
    this.url = `${baseUrl}/customer`;
    this.customerApiKeyUrl = `${baseUrl}/CustomerApiKey`;
  }

  addCustomerProfile(profile: CustomerProfile): Observable<number> {
    return this.http.post(this.url, profile)
      .pipe(
        map((response: CustomerProfile) => response.id as number),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  editCustomerProfile(profile: CustomerProfile, doSubmit: boolean): Observable<Response> {
    return this.http.put(`${this.url}/${doSubmit}`, profile)
      .pipe(
        map(response => response as Response),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  getCustomerProfile(id: number): Observable<CustomerProfile> {
    // console.log(this.headers);
    return this.http.get(this.url + '/' + id)
      .pipe(
        map((response: any) => response as CustomerProfile),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  getCustomerBarcodeCategory(id: number): Observable<any> {
    // console.log(this.headers);
    return this.http.get(this.url + '/BarcodeType/' + id)
      .pipe(
        map((response: any) => response),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  updateCustomerStatus(profile: CustomerProfile): Observable<Response> {
    return this.http.put(`${this.url}/CustomerStatus`, profile)
      .pipe(
        map(response => response as Response),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  getParentCustomers(): Observable<ParentCustomer[]> {
    return this.http.get(this.url + '/GetParentCustomers')
      .pipe(
        map((response: any) => response as ParentCustomer[]),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  getChildren(parentId: number): Observable<ChildCustomer[]> {
    return this.http.get(`${this.url}/GetChildren/${parentId}`)
      .pipe(
        map((response: any) => response as ChildCustomer[]),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  removeChildAssociation(childIdsToRemove: number[]): Observable<any> {
    return this.http.post(this.url + '/RemoveChildAssociation', childIdsToRemove)
      .pipe(
        map((response: any) => response as any),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  getCustomerCompletionStatus(id: number): Observable<any> {
    return this.http.get(this.url + '/' + id + '/status')
      .pipe(
        map(response => response as any),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  genSendShipperRateApiKey(request: CustomerApiKeyRequest): Observable<Response> {
    return this.http.post(`${this.customerApiKeyUrl}`, request)
      .pipe(
        map(response => response as Response),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  /*** Customer Account ***/

  getCustomerAccountTypes(): Observable<CustomerAccountTypes[]> {
    return this.http.get<CustomerAccountTypes[]>(`${this.url}/GetCustomerAccountTypes`)
      .pipe(
        map(response => response),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  getCustomerAccount(customerId: number): Observable<CustomerAccount> {
    let params = new HttpParams();
    params = params.append("CustomerId", customerId);
    return this.http.get<CustomerAccount>(`${this.url}/CustomerAccount`, { params: params })
      .pipe(
        map(response => response),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  updateCustomerAccount(customerId: number, customerAccountTypeId: number): Observable<Response> {
    const customerAccount = {
      customerId,
      customerAccountTypeId
    };

    return this.http.put(`${this.url}/CustomerAccount`, customerAccount)
      .pipe(
        map(response => response as Response),
        catchError(response => {
          this.appInsights.logException(response);
          this.errorHandler.errorChooser(response);
          return throwError(response);
        })
      );
  }

  /*** TargetCustomer updating methods below ***/
  // Available to inheritors of ComponentBase
  updateTargetCustomerFromRoute<T extends ComponentBase>(componentRef: T, route: ActivatedRoute): void {
    componentRef.subscriptions.push(route.firstChild.params.subscribe(
      (params: any) => {
        // console.log('CustomerService component route params: ', params);
        const storedTargetCustomerId = this.store.state.targetCustomer != null ? this.store.state.targetCustomer.id : null;
        const snapshotParams = route.snapshot.params;
        const paramsId = snapshotParams != null ? snapshotParams.id : null;
        if (paramsId != null && +storedTargetCustomerId !== +paramsId) {
          // console.log('..fetching updated name from customerService..');
          componentRef.subscriptions.push(this.getCustomerProfile(paramsId)
            .subscribe(customer => {
              this.store.updateTargetCustomer({
                id: customer.id,
                name: customer.customerName,
                status: customer.customerStatusSystemNumber,
                shipperType: customer.shipperTypeSystemNumber,
                ae: customer.ae,
                alternateAE: customer.alternateAE,
                aeManager: customer.aeManager
              });
            })
          );
        }
      }
    ));
  }

  /*** ShipperType helper methods below ***/
  // Available to inheritors of ComponentBase
  initShipperTypeSubscriber<T extends ComponentBase>(
    componentRef: T, callback: (type: ShipperTypeCategory) => void
  ): void {
    // reduce calls to get customer profile by keying off store values,
    // which global app-nav and customer-name-header should maintain for us
    componentRef.subscriptions.push(this.store.state$
      .pipe(
        map(state => state.targetCustomer),
        distinctUntilChanged()
      )
      .subscribe(
        (targetCustomer: TargetCustomer) => {
          if (targetCustomer != null) {
            if (this.isDomesticOutboundShipper()) {
              callback('domestic');
              return;
            } else if (this.isReturnsShipper()) {
              callback('returns');
              return;
            } else if (this.isInternationalShipper()) {
              callback('international');
              return;
            }
            else if (this.isInternationalWWEShipper()) {
              callback('internationalWWE');
              return;
            }
            else {
              //Returning Null If It Is Not Defined Yet
              callback(null)
              return;
            }
          }
        })
    );
  }

  isBillingShipperType(shipperType: number): boolean {
    const requiredShipperTypes = [
        ShipperType.DomesticOutbound,
        ShipperType.DomesticOutboundGround,
        ShipperType.DomesticOutboundORMD,
        ShipperType.MiReturns];
    return requiredShipperTypes.indexOf(shipperType) > -1;
  }

  isDomesticOutboundShipper = (): boolean => {
    const shipperTypeValues =
      [ShipperType.DomesticOutbound, ShipperType.DomesticOutboundGround, ShipperType.DomesticOutboundORMD];
    return this.store.state.targetCustomer != null &&
      shipperTypeValues.indexOf(this.store.state.targetCustomer.shipperType) > -1;
  }

  isReturnsShipper = (): boolean => {
    // there is only one exposed Returns ShipperType currently, but ok to use array in case others are added/exposed in the future
    const shipperTypeValues = [ShipperType.MiReturns];
    return this.store.state.targetCustomer != null &&
      shipperTypeValues.indexOf(this.store.state.targetCustomer.shipperType) > -1;
  }

  isInternationalShipper = (): boolean => {
    const shipperTypeValues = [ShipperType.International];
    return this.store.state.targetCustomer != null &&
      shipperTypeValues.indexOf(this.store.state.targetCustomer.shipperType) > -1;
  }

  isInternationalWWEShipper = (): boolean => {
    const shipperTypeValues = [ShipperType.InternationalWWE];
    return this.store.state.targetCustomer != null &&
      shipperTypeValues.indexOf(this.store.state.targetCustomer.shipperType) > -1;
  }
}
