/*
for implementation approach reference see:
https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1403
https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-angular-v2-samples/angular10-sample-app/src/app/app.module.ts
https://github.com/vinusorout/msal-angular-dynamic-configuration-sample/blob/master/src/app/msal-config-dynamic-module/msal-config-dynamic.module.ts
*/
import { InjectionToken, NgModule, APP_INITIALIZER } from '@angular/core';
import {
  IPublicClientApplication, PublicClientApplication,
  InteractionType, BrowserCacheLocation, LogLevel
} from '@azure/msal-browser';
import {
  MsalGuard, MsalInterceptor, MsalBroadcastService,
  MsalInterceptorConfiguration, MsalModule, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfigService } from 'src/app/app-config.service';
import { HttpErrorCode } from '../shared/enums/errors.enum';
import { JsonInterceptor } from '../shared/interceptors/json-interceptor';

const APP_DIR = '';
// export const BASE_HREF = window.location.pathname.indexOf(APP_DIR) > -1 ? `/${APP_DIR}/` : '/';
export const BASE_HREF = '/';
console.log(BASE_HREF);
const AUTH_CONFIG_URL_TOKEN = new InjectionToken<string>('AUTH_CONFIG_URL');
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
// const console: ConsoleLogService = new ConsoleLogService();
let allConfig: any = null;

export function initializerFactory(config: AppConfigService, configUrl: string): any {
  // APP_INITIALIZER, accept a function return which will return a promise
  const promise = config.loadAppConfig(configUrl).then((value) => {
    allConfig = config.get();
    console.log('initializerFactory loaded app config: ', allConfig);
  });
  return () => promise;
}

export function loggerCallback(level: LogLevel, message: string, containsPii: boolean) {
  if (containsPii) {
    console.log(message);
  }
  switch (level) {
    case LogLevel.Error:
      console.error(message);
      return;
    case LogLevel.Info:
      console.info(message);
      return;
    case LogLevel.Verbose:
      console.debug(message);
      return;
    case LogLevel.Warning:
      console.warn(message);
      return;
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  console.log('in MSALInstanceFactory.. ');
  const redirectUri = BASE_HREF?.length > 1 ? BASE_HREF.substr(0, BASE_HREF.length - 1) : BASE_HREF; // trim off any trailing slash to match AppReg redirect URIs
  console.log('..redirectUri: ', redirectUri);
  return new PublicClientApplication({
    auth: {
      clientId: allConfig.clientId,
      authority: allConfig.authority,
      redirectUri: redirectUri,
      postLogoutRedirectUri: redirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,//LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback: loggerCallback,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(allConfig.graphEndpoint, ['openid', 'profile', allConfig.consentScope])
    .set(allConfig.apiUrl, [allConfig.apiScope])
    .set(allConfig.billingapiUrl, [allConfig.apiScope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: { scopes: [allConfig.consentScope] },
    loginFailedRoute: HttpErrorCode.Unauthorized.toString() //REVISIT a distinct treatment for failed logins
  };
}

@NgModule({
  providers: [],
  imports: [MsalModule]
})
export class MsalAppConfigModule {

  static forRoot(configFile: string) {
    return {
      ngModule: MsalAppConfigModule,
      providers: [
        AppConfigService,
        { provide: AUTH_CONFIG_URL_TOKEN, useValue: configFile },
        {
          provide: APP_INITIALIZER, useFactory: initializerFactory,
          deps: [AppConfigService, AUTH_CONFIG_URL_TOKEN], multi: true
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JsonInterceptor,
          multi: true,
        }
      ]
    };
  }
}
