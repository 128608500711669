import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { CustomerService } from '../services/customer.service';
import { CustomerProfile } from '../models/customer-profile.model';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';
import { ComponentBase } from '../models/component-base';
import { HttpErrorCode } from '../enums/errors.enum';
import { CmAppStore } from '../services/cm-app-store';
import { ParentChildContext, TargetCustomer } from '../models/cm-state';
import { isNullOrUndefined, jsonEqual } from '../misc/utils';
import { NavKey } from '../models/nav.model';
import { ShipperTypeCategory } from '../enums/shipper-type.enum';
import { UserService } from '../services/user.service';
import { environment } from 'src/environments/environment';
import { AuthHelperService } from '../services/auth-helper.service';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html'
})
export class NavComponent extends ComponentBase implements OnInit, OnDestroy {
    isCollapsed = false;
    showHome = false;
    showTabs = false;
    activeTab: string;
    customerProfile: CustomerProfile;
    navKeys: NavKey[];
    navTabs: NavKey[];
    homeRoute: string;
    parentGroup:string;
    routeNotFound = `/${HttpErrorCode.NotFound.toString()}`;
    routeForbidden = `/${HttpErrorCode.Forbidden.toString()}`;
    routeUnauthorized = `/${HttpErrorCode.Unauthorized.toString()}`;
    errorRoute = true; // presume truthiness although unlikely so the app errs on the secure side of not exposing the user Settings link

    pcContext: ParentChildContext;
    parentChildEnabled = environment.parentChildEnabled;

    constructor(
        msalService: MsalService,
        broadcastService: MsalBroadcastService,
        route: ActivatedRoute,
        public router: Router,
        private customerService: CustomerService,
        private store: CmAppStore,
        private userService: UserService,
        private authHelperService: AuthHelperService
    ) {
        super(msalService, broadcastService, route);

        

        this.customerProfile = new CustomerProfile();
        this.pcContext = new ParentChildContext();
    }

    ngOnInit() {
        this.refreshNav();
        super.ngOnInit();
        
        this.updateNav();

        this.msalTokenUpdateObservable.subscribe(() => this.updateNav());

        this.evaluateUrl();

        this.customerService.initShipperTypeSubscriber(this, (shipperTypeCategory: ShipperTypeCategory) => {
            // console.log('Navbar: onCustomerShipperType() shipperTypeCategory: ', shipperTypeCategory);
            //if (shipperTypeCategory === 'international') {
            // this.navTabs = this.navTabs.filter(x => x.key !== 'transportation');
            //} else
            if (this.navTabs.find(x => x.key === 'transportation') === undefined) {
                this.navTabs = this.navKeys.filter(k => k.isTab);
            }
        });

        this.subscriptions.push(this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(
                (event: NavigationEnd) => {
                    // console.log('NAV event router.parseUrl: ', this.router.parseUrl(event.url));
                    this.evaluateUrl();
                })
        );

        this.subscriptions.push(this.store.state$
            .pipe(
                map(state => state.targetCustomer),
                filter(targetCustomer => !jsonEqual(targetCustomer, this.customerProfile)),
                distinctUntilChanged()
            )
            .subscribe(
                (targetCustomer: TargetCustomer) => {
                    // console.log('In Nav cmpt, appStore targetCustomer observed change: ', targetCustomer);
                    this.clearLocalParentChildContexts();
                    if (targetCustomer != null) {
                        this.getCustomerProfile(targetCustomer.id);
                    }
                })
        );

        this.subscriptions.push(this.store.state$
            .pipe(
                map(state => state.parentChildContext),
                filter(parentChildContext => !jsonEqual(parentChildContext, this.pcContext)),
                distinctUntilChanged()
            )
            .subscribe(
                (parentChildContext: ParentChildContext) => {
                   // console.log('In Nav cmpt, appStore parentChildContext observed change: ', parentChildContext);
                    if (parentChildContext == null) {
                        this.pcContext = new ParentChildContext();
                    }
                })
        );
        this.tileBasedNav();
    }

    evaluateUrl() {
        // console.log('this.router.url: ', this.router.url);
        this.errorRoute = (this.router.url === this.routeNotFound || this.router.url === this.routeForbidden ||
            this.router.url === this.routeUnauthorized);
        this.showHome = false;
        this.showTabs = false;
        this.pcContext.active = false;

        const tree: UrlTree = this.router.parseUrl(this.router.url);
        const segmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        if (segmentGroup != null && segmentGroup.segments != null) {
            const segments: UrlSegment[] = segmentGroup.segments;
            //console.log('..segments:', segments);
            this.activeTab = segments[0].path;
            this.parentGroup = isNullOrUndefined(sessionStorage.getItem("tileName"))?"Customer":sessionStorage.getItem("tileName");
            if(this.parentGroup == 'Customer'){
            const numericalSegment = segments.find(s => !isNaN(+s.path));
            if (numericalSegment != null) {
                this.id = +numericalSegment.path;
                // if the current path is a drill-down into a specific nav section item, show Home (back to section home)
                this.tileBasedNav();
                this.setShowSettings();
             }
            }

            if(this.parentGroup == 'Configuration'){
                this.tileBasedNav();
                this.setShowSettings();
               
            }
        
            const nonNumericShowHome = segments.find(s => s.path == 'add' || s.path == 'new-user');
            if (nonNumericShowHome != null) {
                this.setShowSettings();
            }
        }
    }

    tileBasedNav(){
       const tileconst =  isNullOrUndefined(sessionStorage.getItem("tileName"))?"Customer":sessionStorage.getItem("tileName")
       this.navKeys.forEach(x => x.isTab = tileconst == x.parentGroup);
       if(sessionStorage.getItem("homeNavigation") == "Approval" ||
       sessionStorage.getItem("homeNavigation") == "ActionItemDashboard" ) {
         this.navKeys.forEach(x => x.homeRoute = "approval-notification")
       }
       else if(sessionStorage.getItem("homeNavigation") == "Customer") {
        this.navKeys.forEach(x => x.homeRoute = "customer")
       }
       this.navTabs = this.navKeys.filter(k => k.isTab);  
    }

    refreshNav(){
        this.navKeys = [
            {
                key: 'admin',
                label: 'Admin Functions',
                route: '/admin',
                childRoute: null,
                homeRoute: 'admin',
                customEvent: null,
                isTab: false,
                enabled: true,
                parentGroup:'Admin' //** Tile Name**//
            },
            {
                key: 'customer',
                label: 'Customer',
                route: '/customer',
                childRoute: 'profile',
                homeRoute: 'customer',
                customEvent: null,
                isTab: true,
                enabled: true,
                parentGroup:'Customer' //** Tile Name**//
            },
            {
                key: 'billing',
                label: 'Billing',
                route: '/billing',
                childRoute: null,
                homeRoute: 'customer',
                customEvent: 'checkShipperForBilling',
                isTab: true,
                enabled: false,
                parentGroup:'Customer' //** Tile Name**//
            },
            {
                key: 'operations',
                label: 'Operations',
                route: '/operations',
                childRoute: null,
                homeRoute: 'customer',
                customEvent: 'checkShipperForOps',
                isTab: true,
                enabled: true,
                parentGroup:'Customer' //** Tile Name**//
            },
            {
                key: 'transportation',
                label: 'Transportation',
                route: '/transportation',
                childRoute: null,
                homeRoute: 'customer',
                customEvent: 'checkShipperForTransportation',
                isTab: true,
                enabled: true,
                parentGroup:'Customer' //** Tile Name**//
            },
            {
                key: 'rpf',
                label: 'TPC Dashboard',
                route: '/rpf',
                childRoute: null,
                homeRoute: 'customer',
                customEvent: null,
                isTab: true,
                enabled: true,
                parentGroup:'Configuration' //** Tile Name**//
            },
            {
                key: 'mid-manager',
                label: 'MID Manager',
                route: '/mid-manager',
                childRoute: null,
                homeRoute: 'customer',
                customEvent: null,
                isTab: true,
                enabled: true,
                parentGroup:'Configuration' //** Tile Name**//
            },
            {
                key: 'history',
                label: 'History',
                route: '/history',
                childRoute: 'log',
                homeRoute: 'customer',
                customEvent: null,
                isTab: true,
                enabled: true,
                parentGroup:'Customer' //** Tile Name**//
            },
            {
                key: 'pqr',
                label: 'PQR Feature',
                route: '/pqr',
                childRoute: null,
                homeRoute: 'pqr',
                customEvent: null,
                isTab: false,
                enabled: true,
                parentGroup:'PQR' //** Tile Name**//
            }
        ];
        this.navTabs = this.navKeys.filter(k => k.isTab);
    }

    updateNav() {
        // console.log('UPDATE BILLING NAV');
        // enable Billing tab only if user roles permit
        if (this.userService.userIsRpfBilling() || this.userService.userIsCorpBilling() ||
            this.userService.userIsFinanceRevAccounting() || this.userService.userIsCMAccountExec() 
            || this.userService.userIsCollections() || this.userService.userIsIT()) {
            this.navKeys.find(x => x.key === 'billing').enabled = true;
        }

        if (this.userService.userIsCollections()) {
            this.navKeys.find(x => x.key === 'operations').enabled = false;
            this.navKeys.find(x => x.key === 'transportation').enabled = false;
        }
    }

    setShowSettings() {
        const activeKey = this.navKeys.find(k => k.key === this.activeTab);
        if (activeKey != null) {
            this.showHome = true;
            this.homeRoute = activeKey.homeRoute;
            // console.log('..homeRoute:', this.homeRoute);
            this.showTabs = (activeKey.isTab); 
            // console.log('In Nav cmpt, evaluateUrl calling evalShowRibbon');
            this.evalShowRibbon();
        }
    }

    navigateTab(navItem: NavKey): void {
       
        if (!navItem.enabled) { return; }
        this.isCollapsed = false; // trigger closure of collapsible menu

        if (navItem.customEvent != null) {
            this[navItem.customEvent]();
            return;
        }

        if (navItem.key === 'mid-manager') {
            this.router.navigate([navItem.route]);
            return;
        }

        if (navItem.key === 'rpf') {
            this.router.navigate([navItem.route]);
            return;
        }

        if (navItem.childRoute != null) {
            this.router.navigate([navItem.route, this.id, navItem.childRoute]);
        } else {
            this.router.navigate([navItem.route, this.id]);
        }
    }

    getCustomerProfile(customerId: number): void {
        // console.log('In Nav cmpt, getCustomerProfile passed customerId: ' + customerId + ', this.id: ', this.id);
        this.subscriptions.push(this.customerService.getCustomerProfile(customerId)
            .subscribe(profile => {
                this.customerProfile = profile;
                this.pcContext.parentId = null;
                this.pcContext.isParent = profile != null && profile.isParent;
                if (this.pcContext.isParent) {
                    this.pcContext.parentId = profile.id;
                }
                this.pcContext.hasParent = profile != null && profile.parentCustomerSystemNumber != null;
                if (this.pcContext.hasParent) {
                    this.pcContext.parentId = profile.parentCustomerSystemNumber;
                }
                // console.log('In Nav cmpt, getCustomerProfile calling evalShowRibbon : ', this.customerProfile);
                this.evalShowRibbon();
            })
        );
    }

    evalShowRibbon(): void {
        // console.log('In Nav cmpt, evalShowRibbon...');
        const routeIsParentDash = this.router.url.indexOf('parent-dashboard') > -1;
        this.pcContext.active = (this.showTabs && !routeIsParentDash) &&
            (this.pcContext.isParent === true || this.pcContext.hasParent === true);
        this.pcContext.parentName = this.customerProfile.parentName;
        if (!jsonEqual(this.pcContext, this.store.state.parentChildContext)) {
            this.store.updateParentChildContext(this.pcContext);
        }
        // console.log(`...showRibbon: ${this.pcContext.active}, parentChildContext: `, this.pcContext);
    }

    clearLocalParentChildContexts(): void {
        this.pcContext = new ParentChildContext();
    }

    navToParentCustomerProfile(): void {
        const parentId = this.pcContext.parentId;
        this.store.updateTargetCustomer({
            id: parentId,
            name: this.customerProfile.parentName,
            shipperType: this.customerProfile.shipperTypeSystemNumber,
            ae: this.customerProfile.ae,
            alternateAE: this.customerProfile.alternateAE,
            aeManager: this.customerProfile.aeManager
        });
        this.router.navigate(['/customer', parentId, 'profile']);
    }

    checkShipperForBilling() {
        const primaryRoute = '/billing';
            this.router.navigate([primaryRoute, this.id, 'pricing']);
    }

    checkShipperForOps() {
        const primaryRoute = '/operations';
        this.router.navigate([primaryRoute, this.id, 'cost-center']);
    }

    checkShipperForTransportation() {
        const primaryRoute = '/transportation';
        if (this.customerService.isDomesticOutboundShipper() || this.customerService.isInternationalShipper() || this.customerService.isInternationalWWEShipper()) {
            this.router.navigate([primaryRoute, this.id, 'tsa']);
        } else if (this.customerService.isReturnsShipper()) {
            this.router.navigate([primaryRoute, this.id, 'pickup-details']);
        }
    }

    notifications() {
        this.router.navigate(['/notification-settings']);
    }

    userIsAppValid = ():boolean => {
      return this.userService.userhasValidCMApplicationRole();
    }

    login() {
      this.authHelperService.login();
    }

    logout() {
      this.authHelperService.logout();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}