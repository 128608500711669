import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Subject, Subscription } from 'rxjs';
import { AppInsightsService } from 'src/app/shared/services/appinsights.service';
import { AppConfigService } from './app-config.service';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';
import { CmAppStore } from './shared/services/cm-app-store';
import { AccessError } from './shared/models/cm-state';
import { HttpErrorCode } from './shared/enums/errors.enum';
import { UserSubRoleType } from './shared/enums/user-role-type.enum';
import { AuthHelperService } from './shared/services/auth-helper.service';
import { AuthenticatedUser } from './shared/models/authenticated-user.model';
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'customer-management-frontend';
    inactivityInterval: any;
    inactivityLimitMin: number;
    inactivityLimitTimestamp: Date;
    subscriptions: Subscription[];
    breadCrumb: string;
    verifyingDbUser = false;
    dbUserIsVerified = false;
    isIE = false;
    showDebug = false;
    keyDownCodes = [];
    isUserAuthErrorCondition = false;
    isIframe = false;
    isUserAuthenticated = false;
    userProfile: AuthenticatedUser;
    profileFetchInProgress = false;
    private readonly _destroying$ = new Subject<void>();

    constructor(
        appConfigService: AppConfigService,
        private msalBroadcastService: MsalBroadcastService,
        private userService: UserService,
        private msalService: MsalService,
        private authHelper: AuthHelperService,
        private store: CmAppStore,
        private router: Router,
        public appInsights: AppInsightsService,
        private authHelperService: AuthHelperService
    ) {
        this.subscriptions = [];
        localStorage.clear(); // clean-up from before the switch to sessionStorage
        this.inactivityLimitMin = appConfigService.get('inactivityLimitMin');
        if (window === window.parent) {
            this.resetTimeout();
            this.inactivityInterval = setInterval(() => {
                if (new Date() >= this.inactivityLimitTimestamp) {
                    this.logout();
                }
            }, 10000); //checking every 10 seconds
        }
    }

    ngOnInit(): void {
        // this.isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
        // if (this.isIE) {
        //     var header = document.getElementsByClassName('app-header')[0];
        //     header['style'].top = '80px';
        // }

        // this.subscriptions = [
        // this.broadcastService.subscribe('msal:loginFailure', (payload) => {
        //     // console.log('login failure' + new Date().toISOString());
        //     this.appInsights.logTrace(`MSAL login failure: ${JSON.stringify(payload)}`);
        //     this.msalService.loginRedirect();
        // }),
        // this.broadcastService.subscribe('msal:acquireTokenSuccess', (payload) => {
        //     // console.log('msal:acquireTokenSuccess: ', payload);
        //     if (this.isUserAuthErrorCondition) {
        //         return;
        //     }
        //     const validAppRole = this.userService.userhasValidCMApplicationRole();
        //     if (validAppRole === false) {
        //         this.isUserAuthErrorCondition = true;
        // eslint-disable-next-line max-len
        //         const errorMessage = 'You don\'t appear to have a valid Configuration Management Application role.<br/> Please request the appropriate role via APRS.';
        //         this.accessErrorRedirect({ code: HttpErrorCode.Forbidden, messageHtml: errorMessage });
        //     } else {
        //         this.verifyDbUser();
        //     }
        // }),
        // this.broadcastService.subscribe('msal:acquireTokenFailure', (payload) => {
        //     // console.log('acquire token failure' + new Date().toISOString());
        //     this.appInsights.logTrace(`MSAL acquire token failure: ${JSON.stringify(payload)}`);
        //     this.msalService.loginRedirect();
        // })
        // ];

        this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

        this.checkAndSetActiveAccount();

        this.msalBroadcastService.msalSubject$
            .pipe(takeUntil(this._destroying$))
            .subscribe((result) => {
                const timestamp = new Date().toISOString();
                switch (result.eventType) {
                    case EventType.LOGIN_SUCCESS:
                        console.log('LOGIN_SUCCESS ', timestamp);
                        this.checkAndSetActiveAccount();
                        break;
                    case EventType.ACQUIRE_TOKEN_SUCCESS:
                        console.log('ACQUIRE_TOKEN_SUCCESS ', result?.payload);
                        this.checkAndSetActiveAccount();
                        this.authHelper.setToken((result.payload as AuthenticationResult).idToken);
                        if (this.isUserAuthErrorCondition) {
                            return;
                        }
                        const validAppRole = this.userService.userhasValidCMApplicationRole();
                        if (validAppRole === false) {
                            this.isUserAuthErrorCondition = true;
                            // eslint-disable-next-line max-len
                            const errorMessage = 'You don\'t appear to have a valid Configuration Management Application role.<br/> Please request the appropriate role via APRS.';
                            this.accessErrorRedirect({ code: HttpErrorCode.Forbidden, messageHtml: errorMessage });
                        } else {
                            this.verifyDbUser();
                        }
                        break;
                    case EventType.LOGIN_FAILURE:
                        console.log('LOGIN_FAILURE ', timestamp);
                        break;
                    case EventType.ACQUIRE_TOKEN_FAILURE:
                        console.log('ACQUIRE_TOKEN_FAILURE ', timestamp);
                        this.appInsights.logTrace(`MSAL login failure: ${JSON.stringify(result)}`);
                        this.login();
                        break;
                    case EventType.ACQUIRE_TOKEN_FAILURE:
                        console.log('ACQUIRE_TOKEN_FAILURE ', timestamp);
                        this.appInsights.logTrace(`MSAL acquire token failure: ${JSON.stringify(result)}`);
                        this.login();
                        break;
                    case EventType.LOGOUT_SUCCESS:
                        console.log('LOGOUT_SUCCESS ', timestamp);
                        this.appInsights.logTrace(`MSAL logout success: ${JSON.stringify(result)}`);
                        // this.login();
                        break;
                    default:
                        console.log('some other msal broadcast ', result);
                        break;
                }
            });

        this.msalService.handleRedirectObservable().subscribe();
        this.verifyDbUser();
    }

    checkAndSetActiveAccount() {
        console.log('this.authService.instance.getAllAccounts(): ', this.msalService.instance.getAllAccounts());
        this.isUserAuthenticated = this.msalService.instance.getAllAccounts().length > 0;
        // console.log(this.isUserAuthenticated);
        if (this.isUserAuthenticated) {
            let activeAccount = this.msalService.instance.getActiveAccount();
            if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
                console.log('SETTING ACTIVE ACCOUNT');
                let accounts = this.msalService.instance.getAllAccounts();
                this.msalService.instance.setActiveAccount(accounts[0]);
            }
            //verify user against db or eval roles for cleanup etc...
            const validAppRole = this.userService.userhasValidCMApplicationRole()
            if (validAppRole === false) {
                // this.isUserAuthErrorCondition = true;
                // eslint-disable-next-line max-len
                const errorMessage = 'You don\'t appear to have a valid CM Application role.<br/> Please request the appropriate role via APRS.';
                this.accessErrorRedirect({ code: HttpErrorCode.Forbidden, messageHtml: errorMessage });
            }
            this.getProfile();
        }
    }

    getProfile() {
        if (this.userProfile != null || this.profileFetchInProgress) return;
        console.log('AppComponent getProfile()');
        this.profileFetchInProgress = true;
        this.userService.getAuthUserProfile()
            .subscribe(profile => {
                console.log(profile);
                this.userProfile = profile;
                this.profileFetchInProgress = false;
            });
    }

    private verifyDbUser(): void {
        // In addition to having an appropriate APRS role, users must also have been created by a CM_Admin and exist in the CM database
        if (this.isUserAuthErrorCondition || this.verifyingDbUser || this.dbUserIsVerified || this.msalService.instance.getActiveAccount() == null) {
            return;
        }

        this.verifyingDbUser = true;
        const userCloudId = this.msalService.instance.getActiveAccount().username;
        // console.log('verifyDbUser userCloudId: ', userCloudId);
        this.subscriptions.push(this.userService.getUserByCloudID(userCloudId)
            .subscribe(user => {
                // console.log('appCmpt getUserByCloudID response: ', user);
                if (user == null) {
                    // eslint-disable-next-line max-len
                    console.log('USER WAS NULL');
                    const errorMessage = 'The Configuration Management application setup process is incomplete for your user.<br>Please contact your manager or CM Admin to complete the process.';
                    this.accessErrorRedirect({ code: HttpErrorCode.Forbidden, messageHtml: errorMessage });
                } else {
                    this.evalCleanDbUserRoles(user);
                    this.dbUserIsVerified = true;
                }
                this.verifyingDbUser = false;
            })
        );
    }

    evalCleanDbUserRoles(user: User): void {
        if (user.userRoles == null || user.userRoles.length === 0) { return; }

        // for now there are only two cases of main/APRS roles which have sub-roles persisted in db UserRoles
        // Transportation - TSA; Finance, Revenue, Accounting - F&A, Revenue Management
        // if user has the sub-role but not the main APRS role for either, call back-end to prune it from the db
        // Note - front-end itself does not have the Admin authority to prune from the db, back-end will validate before proceeding
        let cleanDbUserRoles = false;
        user.userRoles.forEach(role => {
            if ((role.roleSystemNumber === UserSubRoleType.TSA && !this.userService.userIsNationalTransportation()) ||
                ((role.roleSystemNumber === UserSubRoleType['F&A'] ||
                    role.roleSystemNumber === UserSubRoleType['Revenue Management']) &&
                    !this.userService.userIsFinanceRevAccounting())
            ) {
                cleanDbUserRoles = true;
            }
        });

        if (cleanDbUserRoles) {
            this.subscriptions.push(this.userService.cleanUserRoles(user)
                .subscribe(response => {
                    // console.log('appCmpt cleanUserRoles response: ', response);
                })
            );
        }
    }

    copyToClipboard(content: string): void {
        const dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = content;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
    }

    changeUrl(): void {
        this.breadCrumb = this.router.url;
        // console.log('AppComponent changeUrl: ' + this.router.url + ', route.snapshot.params.id: ', this.route.snapshot.params.id);
    }

    accessErrorRedirect(accessError: AccessError): void {
        this.store.updateAccessError(accessError);
        this.router.navigate([accessError.code]);
    }

    login() {
        this.authHelper.login();
    }

    logout() {
        this.authHelper.logout();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(x => x.unsubscribe());
        this._destroying$.next(null);
        this._destroying$.complete();
        this.inactivityInterval.clear();
    }

    @HostListener('window:keydown', ['$event']) onKeyDown(e) {
        if (!this.keyDownCodes.includes(e.keyCode)) {
            this.keyDownCodes.push(e.keyCode);
        }
        // cntrl + shift + space
        if (this.keyDownCodes.includes(16) && this.keyDownCodes.includes(17) && this.keyDownCodes.includes(32)) {
            this.showDebug = !this.showDebug;
        }
    }
    @HostListener('window:keyup', ['$event']) onKeyUp(e) {
        this.keyDownCodes = this.keyDownCodes.filter(k => k !== e.keyCode);
    }

    @HostListener('window:click')
    @HostListener('window:keyup')
    @HostListener('window:mousemove')
    @HostListener('window:wheel')
    private resetTimeout(): void {
        const newLimit = new Date();
        newLimit.setMinutes(newLimit.getMinutes() + this.inactivityLimitMin);
        this.inactivityLimitTimestamp = newLimit;
    }
}
