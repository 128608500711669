<div id="cm-error-modal-styles">
  <!-- <div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">ALERT!</h4>
  <button type="button" id="whiteClose" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div> -->
  <div class="border-red-rounded">
    <div class="modal-body">
      <h6 class="text-center font-weight-bold">{{error}}</h6>
    </div>
    <div class="modal-footer border-0">
      <button type="button" class="btn btn-error text-center mr-auto ml-auto" data-dismiss="modal"
        (click)="activeModal.dismiss()">Close</button>
    </div>
  </div>
</div>