const ROLES = [
  "CM_User",
  "CM_Account_Exec",
  "CM_Admin",
  "CM_Approver",
  "CM_Sales_Support",
  "CM_Finance_Accouting_Revenue",
  "F&A",
  "Revenue Management",
  "CM_Ops_Mgmt",
  "CM_Ops_User",
  "CM_Regional",
  "CM_Corp_Billing",
  "CM_RPF_Billing",
  "CM_Transportation",
  "CM_National_Trans",
  "TSA",
  "CM_Industrial_Engineer",
  "CM_Postal",
  "CM_API_ConsumerApps",
  "International_User",
  "Collections",
  "International Super User",
  "International Finance and Set Up",
  "International Transportation",
  "International Sales",
  "MI-IT",
  "Operations_Supervisor",
  "Transportation_User"
];

export default ROLES;
