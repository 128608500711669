import { Inject, Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@Injectable({
    providedIn: 'root'
})
export class AuthHelperService {
  private activeToken: string;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
  ) { }

  login() {
    if (this.msalGuardConfig.authRequest){
      console.log('HIT IF');
      console.log(this.msalGuardConfig.authRequest);
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      console.log('HIT ELSE');
      this.authService.loginRedirect();
    }
  }

  logout() {
    console.log('USING AUTH HELPER FOR LOGOUT');
    const logoutRequest = {
      account: this.authService.instance.getActiveAccount()
    };
    console.log('..logoutRequest: ', JSON.stringify(logoutRequest));
    this.setToken(null);
    window.sessionStorage.clear();
    this.authService.logoutRedirect();
  }

  setToken(token: string): void {
    this.activeToken = token;
  }

  getToken(): string {
    return this.activeToken;
  }
}
