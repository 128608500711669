/*
 * `ng build --configuration=dev` in `CM frontend` pipeline npm `build DEV` step
 * not currently using the `dev` config within angular.json as expected.
 * Until further notice, assume DEV and QA deployments will use shared environment.ts (default, non-custom non-prod config)
 */

export const environment = {
    production: false,
    envName: 'dev',
    customerCreationEnabled: true,
    customerProfileUpdateEnabled: true,
    routeGuardsEnabled: false,
    parentChildEnabled: false
};
