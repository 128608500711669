import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subscription, Subject, Observable, BehaviorSubject } from 'rxjs';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { debounceTime, map, distinctUntilChanged, takeUntil, filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { recordDate, deepCloneByJson } from '../misc/utils';
import { ViewApproval, ViewApprovalUI } from './customer-approval.model';
import { CustomerApprovalService } from '../services/customer-approval.service';
import { ApprovalStatus } from '../enums/approval-status.enum';
import { validations, ValidateInputChars, ValidateIllegalChars } from '../validators/illegal-chars.validator';
import { InputCharsValidation } from './input-chars-validation.model';
import { DocumentUploadType } from '../enums/document-upload-type.enum';
import { UserService } from '../services/user.service';
import { FieldMapItem } from './field-master';
import { CmAppStore } from '../services/cm-app-store';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { AuthHelperService } from '../services/auth-helper.service';
import { AuthenticatedUser } from './authenticated-user.model';
import { AccountInfo } from '@azure/msal-browser';

// defines the Account interface to use to get the user roles from the active account
interface Account extends AccountInfo {
    idTokenClaims?: {
        roles?: string[]
    }
}

@Injectable()
export abstract class ComponentBase implements OnInit, OnDestroy {
    public subscriptions: Subscription[];
    protected modifiedByCloudId: string;
    public currentUserName: string;
    public warning = new Subject<string>();
    public longWarning = new Subject<string>();
    public warningMessage: string;
    public longWarningMessage: string;
    public loading = true;
    public id: number; // cannot be used in global nav component due to relative routing
    public pendingApprovals: ViewApproval[];
    public approvalFieldsMap: FieldMapItem[];
    public anyFieldsPendingApproval = false;
    public allFieldsPendingApproval = false;
    public validation = validations;
    public validateInputChars = ValidateInputChars;
    public validateIllegalChars = ValidateIllegalChars;
    public inputCharsValidation = new InputCharsValidation();
    public documentUploadType = DocumentUploadType;
    public allowEditSave = false;
    public fieldsMap: FieldMapItem[];
    public msalTokenUpdateObservable = new BehaviorSubject<boolean>(false);
    currentUser: Account;
    private readonly _destroying$ = new Subject<void>();
    errorMessage: any;
    constructor(
        protected msalService: MsalService,
        private broadcastService: MsalBroadcastService,
        protected route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.subscriptions = [];
        this.currentUser = this.msalService.instance.getActiveAccount();
        this.modifiedByCloudId = this.currentUser ? this.currentUser.username : '';
        this.currentUserName = this.currentUser ? this.currentUser.name : '';

        this.subscriptions.push(this.broadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
                takeUntil(this._destroying$))
            .subscribe((evt: EventMessage) => {
                if (this.msalService.instance.getAllAccounts().length > 0) {
                    this.currentUser = this.msalService.instance.getActiveAccount();
                    console.log('COMP BASE SUCCESS ', evt?.payload);
                    this.modifiedByCloudId = this.msalService.instance.getActiveAccount().username;
                    this.currentUserName = this.currentUser ? this.currentUser.name : '';
                    console.log(this.currentUserName + "CURRENT USER");
                    this.msalTokenUpdateObservable.next(!this.msalTokenUpdateObservable.getValue);
                }
            }));


        const routeParentId = this.route.parent != null ? this.route.parent.snapshot.params.id : null;
        this.id = this.route.snapshot.params.id != null ? this.route.snapshot.params.id : routeParentId;
        if (this.id != null) {
            this.id = +this.id;
        }

        // REVISIT: success message toast now handled via global (AppComponent-embedded) ToastMessageComponent
        // all usages migrated to that global implementation
        // if warning/longWarning are needed, migrate to that component and enhance it with conditional type/state treatment
        this.warning.subscribe((message) => this.warningMessage = message);
        this.warning.pipe(
            debounceTime(3000)
        ).subscribe(() => this.warningMessage = null);

        this.longWarning.subscribe((message) => this.longWarningMessage = message);
        this.longWarning.pipe(
            debounceTime(20000)
        ).subscribe(() => this.longWarningMessage = null);
    }

    protected evalFields(
        store: CmAppStore,
        userService: UserService,
        customerApprovalService: CustomerApprovalService,
        fieldMapItems: FieldMapItem[],
        id: number = this.id
    ): void {
        userService.evalFieldsForCustomer(this.modifiedByCloudId, id, this.subscriptions, fieldMapItems);

        this.subscriptions.push(store.state$
            .pipe(
                map(state => state.componentFieldMap),
                distinctUntilChanged()
            )
            .subscribe(
                (componentFieldMap: FieldMapItem[]) => {
                    this.fieldsMap = componentFieldMap;
                    if (this.fieldsMap == null) { return; }
                    // console.log('In cmpt base evalFields, appStore componentFieldMap observed change: ', this.fieldsMap);

                    // handle approvals
                    this.approvalFieldsMap = this.fieldsMap.filter(x => x.approvable);
                    if (this.approvalFieldsMap.length > 0 && customerApprovalService != null) {
                        this.evalPendingCustomerApprovals(customerApprovalService, id);
                    } else {
                        this.pendingApprovalsEvalComplete();
                    }

                    // handle save/submit button state
                    // for now, assume that if all fields in the component are the same role,
                    // field-level disabling checks may not be added in the cmpt template
                    // so, set allowEditSave to true if any fieldsMap fields are editable
                    if (this.fieldsMap.filter(x => x.editable).length) {
                        this.allowEditSave = true;
                    }
                })
        );
    }

    // OBSOLETE... Use evalPendingCustomerApprovals instead...
    protected initPendingCustomerApprovals(
        customerApprovalService: CustomerApprovalService,
        approvalFields: FieldMapItem[],
        id: number = this.id
    ): void {
        this.approvalFieldsMap = approvalFields;
        this.evalPendingCustomerApprovals(customerApprovalService, id);
    }

    protected evalPendingCustomerApprovals(customerApprovalService: CustomerApprovalService, id: number = this.id): void {
        this.subscriptions.push(customerApprovalService.getPendingByCustomer(id)
            .subscribe(viewApprovals => {
                if (this.approvalFieldsMap == null) { return; }

                // these must be reset here for when approvals are re-fetched following a cancellation,
                // so that the Save/Save&Submit UI can be properly updated based on freshest state 
                this.anyFieldsPendingApproval = false;
                this.allFieldsPendingApproval = false;

                this.pendingApprovals = viewApprovals;
                let numPendingApprovals = 0;
                this.approvalFieldsMap.forEach(approvalField => {
                    if (this.hasPendingApproval(null, approvalField.key)) {
                        this.anyFieldsPendingApproval = true;
                        numPendingApprovals++;
                    }
                });
                if (numPendingApprovals === this.approvalFieldsMap.length) {
                    this.allFieldsPendingApproval = true;
                }

                this.pendingApprovalsEvalComplete();
            })
        );
    }

    public pendingApprovalsEvalComplete(): void {
        // this hook can be overridden in components which inherit ComponentBase which may need to perform some action
        // such as a re-fetch of the entity payload, once the approvals are loaded/eval'd
        // example case is BarcodeSetup cmpt, which uses a reactive form to set disabled etc states, and
        // as such needs the freshest pending CA data before re-building its form/controls
    }

    // needs to be public, used directly in cmpt templates as fn pipe
    public hasPendingApproval = (entity: any, fieldKey: string): boolean => {
        if (this.pendingApprovals == null || this.approvalFieldsMap == null) { return false; }

        const matchingOrIrrelevantSubField = (approvalItem: ViewApproval, fieldMapItem: FieldMapItem) => {
            if (fieldMapItem?.subFieldName != null) {
                return fieldMapItem.subFieldName === approvalItem.subFieldName && fieldMapItem.subFieldValue === approvalItem.subFieldValue;
            }
            return true;
        };

        const field = this.approvalFieldsMap.find(x => x.key === fieldKey);
        return this.pendingApprovals?.find(x => x.fieldName === field?.fieldName && matchingOrIrrelevantSubField(x, field)) != null;
    }

    // used for multi-instance approvable uploads (eg CustomerContract, Label, etc)
    public attachUploadApprovalIndicators(fileArray: any[], customerApprovals: ViewApproval[]): any[] {
        fileArray.forEach(file => {
            const fileApproval = customerApprovals.find(x => x.newValueDesc === file.documentUploadFileName);
            if (fileApproval != null) {
                file.approvalStatus = fileApproval.approvalStatusSystemNumber;
                file.terminalStatus = file.approvalStatus === ApprovalStatus.Approved || file.approvalStatus === ApprovalStatus.Rejected
                    ? file.approvalStatus : null;
                file.approvalComment = fileApproval.comment; // eg rejected reason
            }
        });

        return fileArray;
    }

    // needs to be public, used directly in cmpt templates as fn pipe
    public isActiveRequired = (entity: any, fieldKey: string): boolean => {
        if (this.fieldsMap != null) {
            const fieldMapItem = this.fieldsMap.find(x => x.key.toUpperCase() === fieldKey.toUpperCase());
            if (fieldMapItem != null && fieldMapItem.requiredForActive) { return true; }
        }
        return false; // default to not required (fields not explicitly in the fieldMap shouldn't be assumed required)
    }

    // needs to be public, used directly in cmpt templates as fn pipe
    public isEditable = (entity: any, fieldKey: string): boolean => {
        // This method is concerned with editability by role/RPF permissions only rather than attempting
        // to take pending approval status into consideration. 
        // For the time being, impact of approval status on editability should be evaluated separately
        if (this.fieldsMap != null) {
            const fieldMapItem = this.fieldsMap.find(x => x.key.toUpperCase() === fieldKey.toUpperCase());
            if (fieldMapItem != null && !fieldMapItem.editable) {
                return false;
            }
        }
        return true; // default to editable at the field level (fields not explicitly in the fieldMap should be assumed editable)
    }

    // needs to be public, used directly in cmpt templates as fn pipe
    public approvalRole = (entity: any, fieldKey: string): string => {
        if (this.fieldsMap != null) {
            const fieldMapItem = this.fieldsMap.find(x => x.key.toUpperCase() === fieldKey.toUpperCase());
            if (fieldMapItem != null) { return fieldMapItem.approvableBy; }
        }
        return '';
    }

    public editConstraints = (entity: any, fieldKey: string): string[]=> {
        if (this.fieldsMap != null) {
            const fieldMapItem = this.fieldsMap.find(x => x.key.toUpperCase() === fieldKey.toUpperCase());
            if (fieldMapItem != null) {
                return fieldMapItem.editableBy;
            }
        }
        return [];
    }

    protected copyOriginalTransaction(transactionObject: any): any {
        // deep clone a copy of the original object for eval on save... REVISIT if unexpected datatyping or `undefined` prop mishandling
        return deepCloneByJson(transactionObject);
    }

    // for convenience, default to updating Modify properties but not Create properties
    protected attachAuditProperties(entity: any, updateModify: boolean = true, updateCreate: boolean = false): any {
        const now = recordDate();
        if (updateModify) {
            entity.recordModifyDate = now;
            entity.recordModifyUserName = this.modifiedByCloudId;
        }
        if (updateCreate) {
            entity.recordCreateDate = now;
            entity.recordCreateUserName = this.modifiedByCloudId;
        }

        return entity;
    }

    public emitCancelApproval(fieldName: string, customerApprovalService: CustomerApprovalService, store: CmAppStore, subFieldValue: number = null) {
        store.showLoadIndicator();
        // gets the correct approval to cancel from pendingApprovals. If there is a subFieldValue it will use that to find the correct approval.
        const approvalToCancel: ViewApprovalUI = subFieldValue == null ?
            this.pendingApprovals.find(x => x.fieldName == fieldName && x.approvalStatusSystemNumber == ApprovalStatus.Pending) as ViewApprovalUI :
            this.pendingApprovals.find(x => x.fieldName == fieldName && x.approvalStatusSystemNumber == ApprovalStatus.Pending && x.subFieldValue == subFieldValue) as ViewApprovalUI;

        // Cancel the approval
        approvalToCancel.approvalStatusSystemNumber = ApprovalStatus.Cancelled;

        // Put the new cancelled approval and display messsage to the user when successfully cancelled
        this.subscriptions.push(
            customerApprovalService.putApprovals([approvalToCancel])
                .subscribe(response => {
                    store.clearLoadIndicator();
                    this.onPendingApprovalCancelled();
                    store.showSuccessMessage("Pending approval updated successfully to Cancelled!");
                })
        );
    }

    public emitCancelApprovalMultiFields(group: string, customerApprovalService: CustomerApprovalService, store: CmAppStore, subFieldValue: number = null) {
        store.showLoadIndicator();
        this.fieldsMap.filter(x=> x.key.startsWith(group)).forEach(item => {
        // gets the correct approval to cancel from pendingApprovals. If there is a subFieldValue it will use that to find the correct approval.
        const approvalToCancel: ViewApprovalUI = subFieldValue == null ?
            this.pendingApprovals.find(x => x.fieldName == item.fieldName && x.approvalStatusSystemNumber == ApprovalStatus.Pending) as ViewApprovalUI :
            this.pendingApprovals.find(x => x.fieldName == item.fieldName && x.approvalStatusSystemNumber == ApprovalStatus.Pending && x.subFieldValue == subFieldValue) as ViewApprovalUI;

        // Cancel the approval
        if(approvalToCancel != undefined)
        {
            approvalToCancel.approvalStatusSystemNumber = ApprovalStatus.Cancelled;
            // Put the new cancelled approval and display messsage to the user when successfully cancelled
            this.subscriptions.push(
                customerApprovalService.putApprovals([approvalToCancel])
                    .subscribe(response => {
                        store.clearLoadIndicator();
                        this.onPendingApprovalCancelled();
                        store.showSuccessMessage("Pending approval updated successfully to Cancelled!");
                    })
            );
        }
    });
    }
    
    public onPendingApprovalCancelled(): void {
        // this hook can be overridden in components which inherit ComponentBase which may need to perform some action
        // such as a re-fetch of the entity payload/customer approvals data following a pending approval cancellation
    }

    ngOnDestroy(): void {
        // console.log('ComponentBase ngOnDestroy subs: ', this.subscriptions);
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
        this._destroying$.next(null);
        this._destroying$.complete();
    }
    validateCopiedText(event:ClipboardEvent){
        this.errorMessage = "";
        const clipboardData = event.clipboardData || window['clipboardData'];
        const pastedText = clipboardData.getData('text');
        if(/\t/.test(pastedText)){
            event.preventDefault();
            this.errorMessage ="Customer Name must not include any tab space" 
        }
        
    }
   onkeyUp(event:KeyboardEvent){
    const inputlength = (event.target as HTMLInputElement).value.length;
    if(inputlength >0){
        this.errorMessage ="";
    }
   }
}
