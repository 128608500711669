import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes, UrlSerializer, UrlTree} from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { NotificationSettingsComponent } from './shared/notification-settings/notification-settings.component';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { NotPermittedComponent } from './errors/not-permitted/not-permitted.component';
import { AdminGuard } from './shared/route-guards/admin.guard';
import { WorkstationGuard } from './shared/route-guards/workstation.guard';
import { BillingGuard } from './shared/route-guards/billing.guard';
import { UnauthorizedComponent } from './errors/unauthorized/unauthorized.component';
import { PQRGuard } from './shared/route-guards/pqr-guard';
import { ApprovalNotificationGuard } from './shared/route-guards/approval-notification.guard';
import { RoleDashboardComponent } from './shared/role-dashboard/role-dashboard.component';
import { LowerEnvGuard } from './shared/route-guards/lower-env.guard';

const routes: Routes = [
    {
        path: 'customer',
        loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin-functions/admin-functions.module').then(m => m.AdminFunctionsModule),
        canActivate: [MsalGuard, AdminGuard]
    },
    {
        path: 'billing',
        loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
        canActivate: [MsalGuard, BillingGuard]
    },
    {
        path: 'operations',
        loadChildren: () => import('./customer-mailpiece/customer-mailpiece.module').then(m => m.CustomerMailpieceModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'transportation',
        loadChildren: () => import('./transportation/transportation.module').then(m => m.TransportationModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'mid-manager',
        loadChildren: () => import('./mid-manager/mid-manager.module').then(m => m.MIDManagerModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'history',
        loadChildren: () => import('./history/history.module').then(m => m.HistoryModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'approval-notification',
        loadChildren: () => import('./approval-notification/approval-notification.module').then(m => m.ApprovalNotificationModule),
        canActivate: [MsalGuard, ApprovalNotificationGuard]
    },
    {
        path: 'landing-page',
        component: LandingPageComponent,
    },
    {
        path: 'notification-settings',
        component: NotificationSettingsComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'rpf',
        loadChildren: () => import('./rpf-config/rpf-config.module').then(m => m.RpfConfigModule),
        canActivate: [MsalGuard, WorkstationGuard]
    },
    {
        path: 'pqr',
        loadChildren: () => import('./pqr/pqr.module').then(m => m.PQRModule),
        canActivate: [MsalGuard, PQRGuard]
    },
    {
        path: 'config',
        component: LandingPageComponent,
        canActivate: [MsalGuard]
    },
    {
        path: '__role-dashboard',
        component: RoleDashboardComponent,
        canActivate: [LowerEnvGuard]
    },
    {
        path: '',
        redirectTo: '/landing-page',
        pathMatch: 'full'
    },
    {
        path: '404',
        component: NotFoundComponent,
        canActivate: [MsalGuard]
    },
    {
        path: '403',
        component: NotPermittedComponent,
        canActivate: [MsalGuard]
    },
    {
        path: '401',
        component: UnauthorizedComponent,
        canActivate: [MsalGuard]
    },
    {
        path: '**',
        redirectTo: '/404'
    }
];

interface MalformedUriError extends ExtraOptions {
    malformedUriErrorHandler?: (error: URIError, urlSerializer: UrlSerializer, url: string) => UrlTree;
}

const isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

const malformedUriError: MalformedUriError = {
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',// Remove this line to use Angular Universal
    malformedUriErrorHandler: malformedUriErrorRouteHandler
};

@NgModule({
    imports: [RouterModule.forRoot(routes,malformedUriError)],
    exports: [RouterModule]
})

export class AppRoutingModule { }

export function malformedUriErrorRouteHandler(error: URIError, urlSerializer: UrlSerializer, url: string): UrlTree {
    // console.log('malformed URI error: ', error);
    // console.log('malformed url: ', url);
    return urlSerializer.parse('/404');
}
