/* values below must match db `ApprovalStatus` table - REVISIT if an explicit lookup API call is preferable to this front-end enum mirror
*  see also CMS.Lib.Enums.Status.cs */
export enum ApprovalStatus {
    Approved = 1,
    Pending = 2,
    Rejected = 3,
    Incomplete = 4,
    Cancelled = 5,
    Suspended = 6,
    Escalated = 7 /* for now, this is only used by the CustomerDash SP for roll-up indicator... */
}
