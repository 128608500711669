import { CustomerRPF } from 'src/app/shared/models/customerRPF.model';
import { EntityBase } from './entity-base.model';
import { OtherIdentifier } from './other-identifiers';

export class CustomerProfile extends EntityBase {
    id: number;
    customerName: string;
    customerStatusSystemNumber: number;
    segmentTypeSystemNumber: number;
    shipperTypeSystemNumber?: number;
    standardIndustryClassificationSystemNumber: number;
    customerRPF: CustomerRPF;
    ae: string;
    alternateAE: string;
    aeManager: string;
    frequencyCode: number;
    barcodeCategorySystemNumber: number;
    isUSPSTrackAsPackageID: boolean;
    customerShippingSystemNumbers: any;
    isParent: boolean;
    parentCustomerSystemNumber: number;
    parentName: string;
    upsAccountNumber: string;
    cmfNumber: string;
    shipperCustomerName: string;
    inactiveReason: string;
    hasShipperRateAPIKey?: boolean;
    thirdPartyTypeSystemNumber?: number;
    otherIdentifier: OtherIdentifier[];


    constructor() {
        super();
        this.customerRPF = new CustomerRPF();
        this.isParent = false;
        this.parentCustomerSystemNumber = null;
    }
}

export class ParentCustomer {
    parentCustomerID: number;
    customerName: string;

    constructor() {
        this.parentCustomerID  = null;
        this.customerName  = 'None';
    }
}

export class ChildCustomer {
    id: number;
    customerName: string;
    customerStatus: string; // like CustomerDashboard, this is passed as the stringy description rather than the CustomerStatusSystemNumber
    rpfMnemonic: string;
    shipperTypeSystemNumber: number;
    ae?: string;
    alternateAE?: string;
    aeManager?: string;
    mid: string;
    upsAccountNumber: string;
    cmfNumber: string;
    recordsTotal: number;
}

export class CustomerApiKeyRequest {
    customerSystemNumber: number;
    isGenerate: boolean;
}
