<div id="cm-landing-page-styles">
  <div class="container pt-5" id="landingSize">
    <h1 id="title" class="display-4 text-center">Mail Innovations Configuration System</h1>
    <h4 *ngIf="!userIsAppValid()" class="sign-in-prompt text-center mb-3">Please <a class="sign-in-link"
        (click)="login()">sign in</a> to proceed</h4>

    <div [ngClass]="{'opacity-75-no-pointer': !validAppPermissions}">
      <div class="svg" [innerHtml]="mySVG | safeHtml" (click)="navigateModule($event)"> </div>
      <div class="mobile-svg" (click)="navigateModule($event)">
        <div id="admin" class="landing-link" [class.disabled]="!adminPermissions" [routerLink]="['/admin']">Admin
          Functions</div>
        <div id="domestic" class="landing-link" [class.disabled]="!pqrPermissions" [routerLink]="['/pqr']">PQR Domestic
        </div>
        <div id="dashboard" class="landing-link" [class.disabled]="!userIsAppValid()" [routerLink]="['/customer']">
          Customer Dashboard</div>
        <div id="international" class="landing-link" [routerLink]="['/']">PQR International</div>
        <div id="approval" class="landing-link" [class.disabled]="!userIsAppValid()"
          [routerLink]="['/approval-notification']">Approval Dashboard</div>
        <div id="processing" class="landing-link" [class.disabled]="!processingPermissions" [routerLink]="['/rpf']">
          Configuration</div>
      </div>
    </div>
  </div>
</div>