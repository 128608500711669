import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AppConfigService } from "src/app/app-config.service";
import AppConfig from './../../../assets/app-config.json';

@Injectable({
  providedIn: "root",
})
export class LowerEnvGuard  {
  constructor(
    private appConfigService: AppConfigService,
    private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      let _devenv = (AppConfig["IsPreProduction"] ?? "false") === "true";
      console.log("RBAC :" + _devenv);
      if (!_devenv) {
        this.router.navigateByUrl('/landing-page');
        return false;
      } else {
        return true;
      }
  }
}
