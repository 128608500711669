import { SearchRecordsRequest } from './search.model';
import { HttpErrorCode } from '../enums/errors.enum';
import { FieldMaster, FieldMapItem } from './field-master';
import { BusinessUnit } from './business-unit.model';
import { UserRole } from './user-role.model';
import { ViewApprovalUI } from './customer-approval.model';

export class CmState {
  targetUser?: TargetEntity; // this is the user being viewed or edited within the User Admin area, not the logged-in user
  currentUser?: CurrentUser; // this is the user currently logged into the application (stored for roles-eval etc purposes)
  targetCustomer?: TargetCustomer;
  targetRPF?: TargetEntity;
  activeCustomerSearch?: SearchRecordsRequest;
  loadIndicatorState?: {
    show: boolean
  };
  successMessageState?: {
    show: boolean,
    message: string
  };
  parentChildContext?: ParentChildContext;
  accessError?: AccessError;
  searchCriteria: ApprovalDashSearchCriteria;
  // REVISIT for a better way of treating FieldMaster and BusinessUnit lookups as singleton
  fieldMaster: FieldMaster[];
  businessUnits: BusinessUnit[];
  componentFieldMap: FieldMapItem[];

  sidenavCollapsed?: boolean;
}

export class ApprovalDashSearchCriteria {
  searchFilter: any[][];
  searchTerm: string;
  pagedItems: ViewApprovalUI[];
  selectedRpfs: number[];
  selectedAreas: string[];
  selectedStatus: string[];
  actionItemFilter: number[];
}

export class TargetEntity {
  id: number;
  name: string;
  status?: number;
}

export class TargetCustomer extends TargetEntity {
  shipperType: number;
  ae?: string;
  alternateAE?: string;
  aeManager?: string;
  homeRpf?: number;
}

export class CurrentUser extends TargetEntity {
  roles?: UserRole[];
  userRpfIds?: number[];
}

export class ParentChildContext {
  active: boolean; // show the ribbon
  isParent: boolean;
  hasParent: boolean;
  parentId: number;
  parentName: string;

  constructor() {
    this.active = false;
    this.isParent = false;
    this.hasParent = false;
    this.parentId = null;
    this.parentName = null;
  }
}

export type AccessErrorCode = HttpErrorCode.NotFound | HttpErrorCode.Forbidden | HttpErrorCode.Unauthorized;
export class AccessError {
  code: AccessErrorCode;
  message?: string;
  messageHtml?: string;
}