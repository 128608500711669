import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct {
        if (!value) {
            return null;
        }

        let date = new Date(value);

        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        }
    }

    format(date: NgbDateStruct): string {
        return date
            ? this.pad(date.month) + '/' + this.pad(date.day) + '/' + date.year
            : '';
    }

    private pad(num: number): string {
        return (num < 10 ? '0' : '') + num;
    }
}
