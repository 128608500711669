import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    godModeCookieName = 'GodMode';

    constructor() { }

    // DISALLOW GODMODECOOKIE IN PRODUCTION!
    getGodModeCookie(): GodModeCookie {
        if (environment.production) { return null; }

        return this.readCookie(this.godModeCookieName);
    }

    getGodModePermission(roleName: string = null, rpfId: number = null): boolean {
        if (environment.production) { return false; }

        const gmc = this.getGodModeCookie();
        const hasUnitRole = (): boolean => {
            if (gmc.hasUnitRoles.indexOf(roleName) > -1) {
                return true;
            }
            return false;
        };
        const hasRpfId = (): boolean => {
            return gmc.hasRpfIds.indexOf(rpfId) > -1;
        };

        if (roleName != null && rpfId == null) {
            if (gmc.hasAllRoles) { return true; }
            return hasUnitRole();
        } else if (rpfId != null && roleName == null) {
            return hasRpfId();
        } else if (roleName != null && rpfId != null) {
            return hasUnitRole() && hasRpfId();
        }
        return false;
    }

    setGodModeCookie(): void {
        const sampleGMC = {hasAllRoles: true, hasUnitRoles: [], hasRpfIds: [15]};
        this.bakeCookie(this.godModeCookieName, sampleGMC);
    }

    bakeCookie(name, value) {
        const cookie =
            [name, '=', encodeURIComponent(JSON.stringify(value)), '; domain=.', window.location.host.toString(), '; path=/;'].join('');
        document.cookie = cookie;
    }

    readCookie(name) {
        const cookieMatch = document.cookie.match(new RegExp(name + '=([^;]+)'));
        let result = null;
        if (cookieMatch != null) {
            const cookieValue = decodeURIComponent(cookieMatch[0].split('=')[1]);
            result = JSON.parse(cookieValue);
        }
        return result;
    }

    deleteCookie(name) {
        document.cookie = [name, '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/; domain=.', window.location.host.toString()].join('');
    }
}

export class GodModeCookie {
    hasAllRoles: boolean;
    hasUnitRoles: string[];
    hasRpfIds: number[];
}
