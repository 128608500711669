import {
    HttpClientModule, HTTP_INTERCEPTORS, HttpInterceptor, HttpRequest,
    HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse,
    HttpUserEvent, HttpErrorResponse
} from '@angular/common/http';
import { NgModule, ApplicationRef, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SharedModule } from '../app/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { AppConfigService } from './app-config.service';
import { CustomFormatter } from './shared/misc/formatters/custom-formatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CmAppStore } from './shared/services/cm-app-store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotPermittedComponent } from './errors/not-permitted/not-permitted.component';
import { HttpErrorCode } from './shared/enums/errors.enum';
import { UnauthorizedComponent } from './errors/unauthorized/unauthorized.component';
import { AppInsightsService } from './shared/services/appinsights.service';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { MsalAppConfigModule } from './auth/auth-config.module';
import { MsalRedirectComponent } from './auth/msal-redirect.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { isNullOrUndefined } from './shared/misc/utils';
import AppConfig from './../assets/app-config.json';

export class CustomHttpInterceptorService implements HttpInterceptor {
    constructor(private router: Router, private appConfigService: AppConfigService, private injector: Injector) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<
        | HttpSentEvent
        | HttpHeaderResponse
        | HttpProgressEvent
        | HttpResponse<any>
        | HttpUserEvent<any>
    > {
        let customReq;
        let lower_env_roles = sessionStorage.getItem('LOWER_ENV_KEY');
        let _devenv = (AppConfig["IsPreProduction"] ?? "false") === "true";
        console.log("RBAC :" + _devenv);
        if (_devenv && !isNullOrUndefined(lower_env_roles)) {
            lower_env_roles = btoa(lower_env_roles);
            customReq = req.clone({ setHeaders: { lower_env_roles }});
        } else {
            customReq = req;
        }

        let appInsights: AppInsightsService;
        const logTrace = (msg: string, err: any): void => {
            // ensure appConfigService loadAppConfig promise has returned before attempting to write logs
            if (this.appConfigService.appConfigAvailable() && appInsights != null) {
                appInsights.logTrace(`HttpInterceptor error: ${msg} \n${JSON.stringify(err)}`);
            }
        };

        // console.log('CustomHttpInterceptorService intercept: ', req);
        return next.handle(customReq).pipe(tap(() => { },
            (err: any) => {
                // use Injector to initialize AppInsightsService only upon receipt of an error rather than via direct DependencyInjection
                // to allow our AppInitializer the chance to load the necessary AppConfig dependency
                if (appInsights == null) {
                    appInsights = this.injector.get(AppInsightsService);
                }

                if (err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        // Note - see https://stackoverflow.com/questions/3297048/403-forbidden-vs-401-unauthorized-http-responses
                        // accepted answer for how this application will treat custom error conditions for 'access' permissions errors
                        case HttpErrorCode.NotFound:
                            logTrace('404 Not Found is http-intercepted by ng app module, redirect', err);
                            this.router.navigate([HttpErrorCode.NotFound.toString()]);
                            break;
                        case HttpErrorCode.Forbidden:
                            logTrace('403 Forbidden is http-intercepted by ng app module,..', err);
                            this.router.navigate([HttpErrorCode.Forbidden.toString()]);
                            break;
                        case HttpErrorCode.Unauthorized:
                            logTrace('401 Unauthorized is http-intercepted by ng app module,...', err);
                            this.router.navigate([HttpErrorCode.Unauthorized.toString()]);
                            break;
                        case HttpErrorCode.BadRequest:
                            logTrace('400 Bad Request is http-intercepted by ng app module,..', err);
                            // TODO - how best to handle without over-reaching
                            break;
                        default:
                            if (err.status === 0) {
                                logTrace('API offline/ connection refused', err);
                            } else {
                                logTrace('some other HttpInterceptor error', err);
                            }
                            break;
                    }
                }
            }));
    }
}

@NgModule({
    declarations: [
        AppComponent,
        MsalRedirectComponent,
        LandingPageComponent,
        NotFoundComponent,
        NotPermittedComponent,
        UnauthorizedComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        SharedModule,
        HttpClientModule,
        AngularSvgIconModule,
        MsalAppConfigModule.forRoot('./assets/app-config.json'),
        BrowserAnimationsModule,
    ],
    // entryComponents: [
    //     AppComponent
    // ],
    providers: [
        AppConfigService,
        CmAppStore,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptorService,
            multi: true,
            deps: [Router, AppConfigService, Injector]
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
               hideRequiredMarker: true,
            },
         },
        { provide: NgbDateParserFormatter, useClass: CustomFormatter }
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
    /* below approach used in previous projects does not appear necessary; remove upon auth implementation finalization
    ngDoBootstrap(ref: ApplicationRef) {
        if (window !== window.parent && !window.opener) {
            ref.bootstrap(MsalComponent);
        } else {
            ref.bootstrap(AppComponent);
        }
    }*/
}
