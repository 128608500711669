import { Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { environment } from '../../../environments/environment';
import { AppConfigService } from 'src/app/app-config.service';
import { Guid } from '../misc/guid';
import { CmAppStore } from './cm-app-store';

export class LogPropertiesObject {
    appSessionId: string; // GUID
    appUserId: number; // User id per db
}

@Injectable({
    providedIn: 'root'
})
export class AppInsightsService {

    private config: AppInsights;
    private traceToConsole = false;
    public sessionId: string = Guid.newGuid();

    constructor(
        appConfigService: AppConfigService,
        private store: CmAppStore
    ) {
        this.config = {
            instrumentationKey: appConfigService.get('instrumentationKey'),
            loggingLevelConsole: environment.production ? 0 : 2,
            isCookieUseDisabled: true, // update disableCookiesUsage: true upon version upgrade, or use cookieCfg post v2.6 
        };

        if (!AppInsights.config) {
            AppInsights.downloadAndSetup(this.config);
        }

        this.traceToConsole = !environment.production;
    }

    logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number): void {
        AppInsights.trackPageView(name, url, properties, measurements, duration);
    }

    logEvent(name: string, properties?: any, measurements?: any) {
        AppInsights.trackEvent(name, properties, measurements);
    }

    logException(exception: Error, handledAt?: string, properties?: any, measurements?: any): void {
        const wrappedException = exception != null && exception.stack != null ?
            exception.stack = this.sessionWrap(exception.stack) : exception;
        AppInsights.trackException(wrappedException, handledAt, this.fallthroughProperties(properties), measurements);
    }

    logTrace(message: string, properties?: any, severityLevel?: any): void {
        const wrappedMessage = this.sessionWrap(message);
        AppInsights.trackTrace(wrappedMessage, this.fallthroughProperties(properties), severityLevel);
        if (this.traceToConsole &&  console != null) {
            console.log(wrappedMessage);
        }
    }

    sessionWrap(message): string {
        return `[ClientSessionId: ${this.sessionId}] ${message}`;
    }

    fallthroughProperties(properties: any): any {
        if (properties != null) { return properties; }

        const defaultProperties = new LogPropertiesObject();
        defaultProperties.appSessionId = this.sessionId;
        const currentUser = this.store.state.currentUser;
        defaultProperties.appUserId = currentUser != null ? currentUser.id : null;
        return defaultProperties;
    }

}
