// values below must match db `DocumentUploadType` table
// REVISIT if an explicit lookup API call is preferable to this front-end enum mirror
export enum DocumentUploadType {
    Contract = 1,
    LabelOnPackage = 2, // this is `Labels` in db
    ApprovableLabel = 3, // this is `IMPB` in db
    TLC = 4,
    CustomerNote = 5,
    PQR = 6,
    TariffRates = 7,
    ConsolidatedBilling = 8,
    SortEntryPlan = 9,
    failedSortEntryPlan = 10,
    CustomRates=11
}
