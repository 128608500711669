import { AbstractControl } from '@angular/forms';
import { InputCharsValidation } from '../models/input-chars-validation.model';

export const validations = Object.freeze({
  illegalChars: /^[a-zA-Z\d\-_.,\s]+$/,
  number: /^\d+$/,
  numberError: 'Input must include numbers only',
  requiredField: /.*\S.*/,
  requiredNoIllegalChars: /^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,200}$/,
  requiredNoIllegalCharsError: 'Input must not contain special characters',
  email: /^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{2,3})+$/,
  //email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, // locks up app for long (>25 char) inputs
  emailError: 'User must have a valid email address',
  upsEmail: /^\w+([\.-]?\w+)*@ups.com|UPS.COM|UPS.com+$/,
  upsEmailError: 'User must have a valid ups.com email address',
  zipcode: /^[0-9]{5}$|^(?=.*[0-9\-]{10}$)(?=.*.....-....).*/,
  zipcodeError: 'Zipcode must be numeric and in the format xxxxx OR xxxxx-xxxx',
  zipcodeNonUS: /^[A-Za-z0-9-]*$/,
  zipcodeErrorNonUS: 'Zipcode must be only alpha-numeric',
  threeDigitZipcode: /^[0-9]{3}$/,
  threeDigitZipcodeError: 'Zipcode must be numeric and not exceed 3 digits',
  fiveDigitZipcode: /^[0-9]{5}$/,
  fiveDigitZipcodeError: 'Zipcode must be numeric and not exceed 5 digits',
  dockNumber: /^[A-Za-z0-9]{1,5}$/,
  dockNumberError: 'Dock # must be alphanumeric and cannot exceed 5 digits',
  phoneNumber: /^(?=.*[0-9\-]{15}$)(?=.*..-...-...-....).*|^(?=.*[0-9\-]{12}$)(?=.*...-...-....).*/,
  phoneNumberError: 'Domestic number format: xxx-xxx-xxxx, International number format xx-xxx-xxx-xxxx',
  extNumber: /^[0-9]{0,10}$/,
  extNumberError: 'Extention can be up to 10 digits',
  date: /^[-:()\/ a-zA-Z0-9]+$/,
  cmfNumber: /^[0-9]{9}$/,
  cmfNumberError: 'Must be a 9 digit number',
  acceptableWgtVariance: /^[\d]{0,2}$/,
  acceptableWgtVarianceError: 'Only numeric value is permitted',
  upsAccountNumber: /^(?!000000)[abcdefghjklmnprtuvwxyzABCDEFGHJKLMNPRTUVWXYZ0-9]{6}$/,
  upsAccountNumberError: 'Must be 6 characters long including only numbers and characters a,b,c,d,e,f,g,h,j,k,l,m,n,p,r,t,u,v,w,x, y and z',
  mailerIds: /^([0-8])([0-9]{5})$|^(9)([0-9]{8})$/,
  mailerIdsError: 'Enter a 6 or 9 digit number; 9-digit numbers must start with a 9',
  otherMailerIds: /^[0-9\-\,\ ]+$/,
  // returnsMailerId: /^[0-9]{9}$/, // for now, assume same rules as domestic OB MIDs
  // returnsMailerIdError: 'Must be a 9 digit number', // for now, assume same rules as domestic OB MIDs
  mailerAlphaCode: /^M\d{1,4}$/,
  mailerAlphaCodeError: 'Input must begin with \'M\' followed by up to four numerical digits',
  currencyUsd: /^\d{1,4}(\.\d{2})?$/,
  customerNote: /^(?!\s*$)[a-zA-Z\d\-@!#$?&+=()_.,'\s]{1,1000}$/,
  customerNoteError: 'Notes cannot be empty, must not contain illegal characters, and have less than 1000 characters',
  customerName: /^(?!\s*$)[a-zA-Z\d\-_:,.'()\s]{1,50}$/,
  customerNameError: 'Customer Name must be less than 50 characters long and must not include any special characters',
  pgNameError: 'Processing Group max length is 8 characters',
  pgNameSpaceError: 'Processing Group name must contain alphanumeric characters',
  pgNumeric:  /^\d+$/,
  pgNumericError: 'Only numeric value is permitted',
  workstationNoIllegalChars: /^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,50}$/, //SortProfile also leveraging
  workstationNoIllegalCharsError: 'Input must be under 50 characters long and not contain special characters', //SortProfile also leveraging
  TransferProfileNoIllegalChars: /^(?!\s*$)(?!^[-_]*$)[A-Za-z0-9-_\s]{1,50}$/,
  TransferProfileNoIllegalError: 'Transfer Profile Name must between 1 to 50 characters long and not contain any special characters (aside from "-" or "_")',
  ipAddress: /^(?!\s*$)[0-9.]{1,50}$/,
  ipAddressError: 'Invalid IP Address',
  packageConfig: /^\d{0,6}?(\.\d{1,2})?$/,
  packageConfigError: 'Invalid Package Configuration. Input can be upto 6 digits long and allows 2 decimal places.',
  sortProfileDestination: /^(?!\s*$)[a-dg-qs-zA-DG-QS-Z0-9]{1}$/,
  sortProfileDestinationError: 'Destination allows a single alphanumeric character value, except: \'E\',\'F\', \'R\'',
  scn: /^[a-zA-Z0-9]{1,20}$/, //clarify length range if any
  scnError: 'SCN allows comma-separated alphanumeric values only',
  customerID: /^[1-9]{1}[0-9]{0,10}$/, //clarify length range if any...
  customerIDError: 'Customer ID allows comma-separated numeric values without leading zeroes only',
  manifestID: /^[1-9]{1}[0-9]{0,1}$/, //db shows valid value range is 0-13 in current state
  manifestIDError: 'Manifest ID allows valid comma-separated numeric values without leading zeroes only',
  subManifestID: /^(0|[1-9]{1}[0-9]{0,1})$/, //db shows valid value range is 0-13 in current state
  subManifestIDError: 'SubManifest ID allows valid comma-separated numeric values without leading zeroes only',
  comingle: /^[0-9]{1,20}$|^D$|^Z$|^MXD$/i,
  comingleError: 'Comingle allows comma-separated numeric values or \'D\', \'Z\', \'MXD\'',
  drpf: /^[1-9]{1}[0-9]{0,1}$/, //combine with check against db of valid rpf id's
  drpfError: 'DRPF ID allows valid comma-separated numeric values only',
  contractCode: /^T01$|^T02$|^T99$|^H51$|^H52$/i,
  contractCodeError: 'Contract Code allows valid comma-separated values only (\'T01\', \'T02\', \'T99\', \'H51\', \'H52\')',
  primarySortCode: /^[0-9]{0,10}$/, //clarify length range if any
  primarySortCodeError: 'Primary Sort Code allows valid comma-separated integer values only',
  secondarySortCode: /^([^0]{1}[0]{2}[0-9]{1})$|^MISC$/, //clarify tightness of allowed values
  secondarySortCodeError: 'Secondary Sort Code allows valid comma-separated 4-digit numerical values or \'MISC\' only',
  legacyReferenceField:/^[\d]{0,19}$/,
  legacyReferenceFieldError: 'Legacy Reference Field allows only numeric values upto 19 digits long.',
  intlAccNumber: /^([\d\w]{3,8}-[\d]{3,5})$/,
  intlAccNumberError: 'International Account Number allows XXXXXXXX-00000 format, where X\'s are alphanumeric and must be 3 to 8 digits long, and 0\'s are numeric and must be 3 to 5 digits long.',
  uldAndAveragePieceCount: /^\d{1,7}(\.\d{0,4})?$/,
  uldAndAveragePieceCountError: 'Must be a number that does not exceed 7 digits followed by up to 4 decimal places. example: x,xxx,xxx.xxxx',
  averageWeightPerPiece: /^\d{1,2}(\.\d{0,4})?$/,
  averageWeightPerPieceError: 'Must be a number that does not exceed 2 digits followed by up to 4 decimal places. example: xx.xxxx',
  originRpf:/^[0-9]{1,2}$/,
  originRpfError:'Max 2 Numeric Digits Allowed',
  destinationRpf:/^[0-9]{1,3}$/,
  destinationRpfError:'Max 3 Numeric Digits Allowed'
});

export function ValidateIllegalChars(control: AbstractControl) {
  // assume name type for use of this abstract control validator;
  // other application field types user ValidateInputChars in the context of template-driven forms
  const result = ValidateInputChars(control.value, 'name');
  if (result.illegalCharStr !== '') {
    return {
      illegalCharsFound: true,
      illegalCharStr: result.illegalCharStr,
      cleanedInput: result.cleanedInput
    };
  }
  return null;
}

export function ValidateInputChars(input: string, type: string = null): InputCharsValidation {
  const illegalChars = /[<>\\\[\];~`\*\^"@{}]+/g;
  const onlyForUrl = /[:\/]+/g;
  const illegalForUrl = /[\s,]+/g;
  const onlyForColor = /[()]+/g;
  const illegalForName = /[#,?]+/g;
  const illegalForPadding = /[#,]+/g;
  const regexPatterns = [illegalChars];

  if (type === 'url') {
    regexPatterns.push(illegalForUrl);
  } else {
    regexPatterns.push(onlyForUrl);
  }
  if (type !== 'color') {
    regexPatterns.push(onlyForColor);
  }
  if (type === 'name') {
    regexPatterns.push(illegalForName);
  }
  if (type === 'padding') {
    regexPatterns.push(illegalForPadding);
  }

  let match;
  let cleanedInputString = input;
  let illegalCharString = '';
  const illegalCharsMatched = [];

  regexPatterns.forEach(re => {
    do {
      match = re.exec(input);
      if (match) {
        if (illegalCharsMatched.indexOf(match[0]) < 0) {
          illegalCharsMatched.push(match[0]);
        }
        cleanedInputString = cleanedInputString.replace(match[0], '');
        // console.log('cleanedInput: ', cleanedInput.replace(match[0], ''));
      }
    } while (match);
  });

  illegalCharString = illegalCharsMatched.join('').split('').filter((item, i, ar) => ar.indexOf(item) === i).join('');

  return {
    illegalCharStr: illegalCharString,
    cleanedInput: cleanedInputString
  };
}
