import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppInsightsService } from './appinsights.service';
import { AppConfigService } from 'src/app/app-config.service';
import { BusinessUnit } from '../models/business-unit.model';
import { ErrorHandlerService } from './error-handler.service';
import { FieldMaster } from '../models/field-master';
import { ContractCode } from '../models/return-mailer-details.model';
import { CustomerStatusModel } from '../models/customer-status.model';
import { CmAppStore } from './cm-app-store';
import { PQRStatus } from '../models/pqr-customer.model';
import { Tariff } from '../models/tariff.model';
import { CreditTermModel } from '../models/credit-term.model';
import { ContactTitle } from '../models/contact-title.model';
import { ContactCategory } from '../models/contact-category.model';
import { SortRuleColor } from '../models/sort-profile.model';
import { ActionItemType } from '../models/action-item.model';
import { FileType } from '../models/file-type.model';
import { RPF } from '../models/rpf.model';


@Injectable({
    providedIn: 'root'
})
export class LookUpService {

    private url: string;
    today = new Date();
    date = `${this.today.getFullYear()}-${(this.today.getMonth() + 1)}-${this.today.getDate()}`;
    time = `${this.today.getHours()}:${this.today.getMinutes()}:${this.today.getSeconds()}`;
    dateTime = `${this.date} ${this.time}`;

    constructor(
        appConfigService: AppConfigService,
        private http: HttpClient,
        private store: CmAppStore,
        private appInsights: AppInsightsService,
        private errorHandler: ErrorHandlerService) {
        this.url = appConfigService.get('apiUrl');
    }

    getShipperTypes(): Observable<any[]> {
        return this.http.get(this.url + '/shippertype/shippertypewithoutprs')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getRPF(): Observable<RPF[]> {
        return this.http.get(this.url + '/RPF')
            .pipe(
                map(response => response as RPF[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    //created to help ensure consistency when filtering RPF data from db
    //(include/exclude internal facilities, intl facilities etc per global reqs)
    filterSupportedRPFs(rpfs: RPF[]): RPF[] {
      return rpfs?.filter(x => x.id != 98);
    }

    getCountries(): Observable<any[]> {
        return this.http.get(this.url + '/Country')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getMailClass(): Observable<any[]> {
        return this.http.get(this.url + '/MailClass')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getMailClassManifest(): Observable<any[]> {
        return this.http.get(this.url + '/MailClass/ManifestMailClasses')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getMailClassChecked(id: number): Observable<any[]> {
        return this.http.get(this.url + '/MPMMailClass/ManifestMailClasses/' + id)
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getSIC(): Observable<any[]> {
        return this.http.get(this.url + '/StandardIndustryClassification')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getSegment(): Observable<any[]> {
        return this.http.get(this.url + '/SegmentType')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getFrequency(): Observable<any[]> {
        return this.http.get(this.url + '/Frequency')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getEndorsementType(): Observable<any[]> {
        return this.http.get(this.url + '/EndorsementType')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getBusinessUnits(): Observable<BusinessUnit[]> {
        if (this.store.state.businessUnits != null) {
            const storedResult = this.store.state.businessUnits;
            // console.log('getBusinessUnits() stored result: ', storedResult);
            return of(storedResult);
        }
        return this.http.get(this.url + '/BusinessUnit')
        .pipe(
            map(response => {
                const typedResponse = response as BusinessUnit[];
                this.store.updateBusinessUnits(typedResponse);
                // console.log('getBusinessUnits() fetched result: ', typedResponse);
                return typedResponse;
            }),
            catchError(response => {
                this.appInsights.logException(response);
                this.errorHandler.errorChooser(response);
                return throwError(response);
            })
        );
    }

    getBusinessUnitById(id: number): Observable<BusinessUnit> {
        if (this.store.state.businessUnits != null) {
            const storedResult = this.store.state.businessUnits.find(x => x.id === id);
            if (storedResult != null) {
                // ('getBusinessUnitById() stored result: ', storedResult);
                return of(storedResult);
            }
        }
        return this.http.get(`${this.url}/BusinessUnit/${id}`)
            .pipe(
                map(response => response as BusinessUnit),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getBarcodeCategory(): Observable<any[]> {
        return this.http.get(this.url + '/BarcodeCategory')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getAeManagers(): Observable<any[]> {
        return this.http.get(this.url + '/User/Managers')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }
    getAccountExec(): Observable<any[]> {
        return this.http.get(this.url + '/UsersGraph/GroupMembers/CM_Account_Exec')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }
    getAccountExecFromGraphDB(): Observable<any[]> {
        return this.http.get(this.url + '/UsersGraph/AEGroupMembers/CM_Account_Exec')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }
    getProcessingRules(): Observable<any[]> {
        return this.http.get(this.url + '/ProcessingRules')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }
    getProcessWithIMPBRules(): Observable<any[]> {
        return this.http.get(this.url + '/ProcessWithIMPBRules')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getFCEWeights(): Observable<any[]> {
        return this.http.get(this.url + '/FCEWeights')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }
    getAutoSwitch(): Observable<any[]> {
        return this.http.get(this.url + '/MailClass')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getDduRates(): Observable<any[]> {
        return this.http.get(this.url + '/DDUEntry')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getWeightFlags(): Observable<any[]> {
        return this.http.get(this.url + '/WeightFlags')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getCustomerShippingSystems(): Observable<any[]> {
        return this.http.get(this.url + '/CustomerShippingSystems')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getWorkstationTypes(): Observable<any[]> {
        return this.http.get(this.url + '/WorkstationTypes')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getFieldMaster(): Observable<FieldMaster[]> {
        if (this.store.state.fieldMaster != null) {
            const storedResult = this.store.state.fieldMaster;
            // console.log('getFieldMaster() stored result: ', storedResult);
            return of(storedResult);
        }
        return this.http.get(this.url + '/FieldMaster')
            .pipe(
                map(response => {
                    const typedResponse = response as FieldMaster[];
                    this.store.updateFieldMaster(typedResponse);
                    // console.log('getFieldMaster() fetched result: ', typedResponse);
                    return typedResponse;
                }),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getPackageOptions(): Observable<any[]> {
        return this.http.get(this.url + '/PackageOptions')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getPQRStatuses(): Observable<PQRStatus[]> {
        return this.http.get(`${this.url}/PQRStatus`)
            .pipe(
                map(response => response as PQRStatus[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getScaleTypes(): Observable<any[]> {
        return this.http.get(this.url + '/ScaleTypes')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getBarcodeSymbology(): Observable<any[]> {
        return this.http.get(this.url + '/BarcodeSymbology')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getPickupUnit(): Observable<any> {
        return this.http.get(this.url + '/PickupUnit')
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getPickupExtra(): Observable<any> {
        return this.http.get(this.url + '/PickupExtra')
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getIntlPortEntry(): Observable<any> {
        return this.http.get(this.url + '/IntlPortEntry')
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getTimeZones(): Observable<any> {
        return this.http.get(this.url + '/PickupTimeZone')
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getContractCodes(): Observable<ContractCode[]> {
        return this.http.get(this.url + '/ContractCode')
            .pipe(
                map(response => response as ContractCode[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getCustomerStatuses(): Observable<CustomerStatusModel[]> {
        return this.http.get(this.url + '/CustomerStatus')
            .pipe(
                map(response => response as CustomerStatusModel[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getCreditTerms(): Observable<CreditTermModel[]> {
        return this.http.get(this.url + '/CreditTerms')
            .pipe(
                map(response => response as CreditTermModel[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getTariffMailClass(): Observable<any> {
        return this.http.get(this.url + '/MailClass/TariffMailClasses')
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getTariffs(): Observable<Tariff[]> {
        return this.http.get(this.url + '/Tariff')
            .pipe(
                map(response => response as Tariff[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getContactTitles(): Observable<ContactTitle[]> {
        return this.http.get(this.url + '/ContactTitle')
            .pipe(
                map(response => response as ContactTitle[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getContactCategories(): Observable<ContactCategory[]> {
        return this.http.get(this.url + '/ContactCategory')
            .pipe(
                map(response => response as ContactCategory[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getSortRuleColors(): Observable<SortRuleColor[]> {
        return this.http.get(this.url + '/Color')
            .pipe(
                map(response => response as SortRuleColor[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getThirdPartyTypes(): Observable<any[]> {
        return this.http.get(this.url + '/ThirdPartyTypes')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getActionItemTypes(): Observable<ActionItemType[]> {
        return this.http.get(this.url + '/ActionItemType')
            .pipe(
                map(response => response as ActionItemType[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getFileTypes(): Observable<FileType[]> {
        return this.http.get(this.url + '/SEPFileSubType')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getUsersReport(): Observable<any[]> {
        return this.http.get(this.url + '/UsersGraph/UserReport')
            .pipe(
                map(response => response as any[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }
    

    downloadUsersReport(): Observable<any> {
        return this.http.get(this.url + '/UsersGraph/UserReport', {responseType: 'text' as 'text' 
    })
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }
}
