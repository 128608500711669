import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, Subscription, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserRpf } from '../models/user-rpf.model';
import { User, GraphUser } from '../models/user.model';
import { AppInsightsService } from './appinsights.service';
import { AppConfigService } from 'src/app/app-config.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { MsalService } from '@azure/msal-angular';
import { RoleStorageService, RoleStore } from './role-storage.service';
import { UserRolesEnum } from '../enums/user-role-type.enum';
import { FieldMapItem, FieldMaster } from '../models/field-master';
import { LookUpService } from './look-up.service';
import { BusinessUnit } from '../models/business-unit.model';
import { CmAppStore } from './cm-app-store';
import { TargetCustomer, CurrentUser } from '../models/cm-state';
import { CustomerService } from './customer.service';
import { UserRole } from '../models/user-role.model';
import { Router } from '@angular/router';
import { HttpErrorCode } from '../enums/errors.enum';
import { CookieService } from './cookie.service';
import { AuthenticatedUser } from '../models/authenticated-user.model';
import { AccountInfo, LogLevel } from '@azure/msal-browser';
import { isNullOrUndefined } from '../misc/utils';
import { environment } from '../../../environments/environment';
import AppConfig from '../../../assets/app-config.json';

// defines the Account interface to use to get the user roles from the active account
interface Account extends AccountInfo {
    idTokenClaims?: {
        roles?: string[]
    }
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private url: string;
    private usersGraphUrl: string;
    private loggedInUser = new BehaviorSubject<AuthenticatedUser>(new AuthenticatedUser());
    private roleStore: RoleStore;

    constructor(
        appConfigService: AppConfigService,
        private http: HttpClient,
        public msalService: MsalService,
        public roleStorage: RoleStorageService,
        private lookUpService: LookUpService,
        private customerService: CustomerService,
        private store: CmAppStore,
        private appInsights: AppInsightsService,
        private errorHandler: ErrorHandlerService,
        private cookieService: CookieService,
        private router: Router
    ) {

        const baseUrl = appConfigService.get('apiUrl');
        this.url = baseUrl + '/user';
        this.usersGraphUrl = `${baseUrl}/UsersGraph`;

        let _devenv = (AppConfig["IsPreProduction"] ?? "false") === "true";
        if(_devenv) {
            this.roleStorage.fetchRoleStore().subscribe(store => {
                this.roleStore = store;
            })
        }
    }

    getUser(id: number): Observable<User> {
        return this.http.get(this.url + '/' + id)
            .pipe(
                map(response => response as User),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getUserByCloudID(azureADID: string): Observable<User> {
        // console.log('getUserByCloudID azureADID: ', azureADID);
        return this.http.post(this.url + '/GetByCloudID', { cloudId: azureADID })
            .pipe(
                map(response => response as User),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    addUser(user: User): Observable<any> {
        return this.http.post(this.url + '/', user)
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    putUser(user: User): Observable<any> {
        return this.http.put(this.url + '/', user)
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    deleteUser(id: number): Observable<Response> {
        return this.http.delete(this.url + '/' + id)
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getUsers() {
        return this.http.get(this.url + '/operationsusers')
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getCustUsers() {
        return this.http.get(this.url + '/')
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getUserRpfsByUserId(userId: number): Observable<UserRpf[]> {
        // note the string concatenation, this is calling UserRpf/ByUserId endpoint...
        return this.http.get(this.url + 'RPF/ByUserId/?userSystemNumber=' + userId)
            .pipe(
                map(response => response as UserRpf[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getUserRpfsByCloudId(cloudID: string): Observable<UserRpf[]> {
        // note the string concatenation, this is calling UserRpf/ByAzureAd endpoint...
        return this.http.post(this.url + 'RPF/ByAzureAd', { cloudId: cloudID })
            .pipe(
                map(response => response as UserRpf[]),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    putUserRpfs(rpfs: UserRpf[]): Observable<Response> {
        return this.http.put(this.url + 'rpf', rpfs)
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    putUserNotificationsByRpf(rpfs: UserRpf[]): Observable<Response> {
        return this.http.post(this.url + 'rpf', rpfs)
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    /* not currently used, replaced by getManagers() */
    getGraphUsers(): Observable<any> {
        return this.http.get(this.usersGraphUrl)
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getGraphUserByCloudID(azureADID: string): Observable<GraphUser> {
        return this.http.post(this.usersGraphUrl + '/', { cloudId: azureADID })
            .pipe(
                map(response => response as GraphUser),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getManagers(): Observable<any> {
        return this.http.get(this.url + '/Managers')
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    getUserNameByAdid = (users: User[], adid: string): string => {
        const user = users.find(u => u.azureADID === adid);
        return user == null ? adid : `${user.firstName.substr(0, 1)}. ${user.lastName}`;
    }

    cleanUserRoles(user: User): Observable<any> {
        return this.http.put(`${this.url}/CleanUserRoles`, user)
            .pipe(
                map(response => response as any),
                catchError(response => {
                    this.appInsights.logException(response);
                    this.errorHandler.errorChooser(response);
                    return throwError(response);
                })
            );
    }

    /* helper method(s) for retrieving role-based permissions via MSAL token data - REVISIT to possibly extract to separate service */
    // methods below accept optional user param which may be of type User or GraphUser
    userhasValidCMApplicationRole(user: any = null): boolean {
        return this.userIsCMUser() || this.userIsCMAccountExec() || this.userIsCMAdmin() || this.userIsCMApprover();
    }
    userIsCMUser(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.User);
    }
    userIsCMAdmin(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.Admin);
    }
    userIsCMApprover(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.Approver, user);
    }
    userIsCMAccountExec(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.AccountExec);
    }
    userIsFinanceRevAccounting(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.Finance, user);
    }
    userIsIndustrialEngineer(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.IndustrialEngineer, user);
    }
    userIsOperations(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.OpsUser, user);
    }
    userIsOperationsSupervisor(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.OpsSupervisor, user);
    }
    userIsOpsManager(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.OpsManager, user);
    }
    userIsCMSalesSupport(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.SalesSupport);
    }
    userIsTransportation(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.Transportation, user);
    }
    userIsNationalTransportation(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.NationalTrans, user);
    }
    userIsRpfBilling(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.RpfBilling, user);
    }
    userIsCorpBilling(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.CorpBilling, user);
    }
    userIsRegional(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.Regional, user);
    }
    userIsIT(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.IT, user);
    }

    async userIsTSA(): Promise<boolean> {
      return await this.userHasSubrole(UserRolesEnum.TSA);
    }

    userIsCollections(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.Collections, user);
    }
    
    userIsInternational(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.International, user);
    }

    async userIsInternationalSuperUser(): Promise<boolean> {
        return await this.userHasSubrole(UserRolesEnum.InternationalSuperUser);
    }

    async userIsInternationalTransportation():Promise<boolean> {
        return await this.userHasSubrole(UserRolesEnum.InternationalTransportation);
    }

    async userIsInternationalFinanceAndSetUp(): Promise<boolean> {
        return await this.userHasSubrole(UserRolesEnum.InternationalFinanceAndSetUp);
    }

    async userIsInternationalSales(): Promise<boolean> {
        return await this.userHasSubrole(UserRolesEnum.InternationalSales);
    }
    userIsTransportationUser(user: any = null): boolean {
        return this.userHasRole(UserRolesEnum.TransportationUser, user);
    }
    
    userHasRole(roleTokenValue: string, user: any = null): boolean {
        if (this.gmcRoleBypass(roleTokenValue)) { return true; }
        const userAccount: Account = this.msalService.instance.getActiveAccount();
        const userRoles = userAccount?.idTokenClaims?.roles;

        let _devenv = (AppConfig["IsPreProduction"] ?? "false") === "true";
        if (_devenv && this.roleStore.isCustom && userRoles.includes("CM_Admin")) {
            return this.roleStore.roles.filter(rr => rr.checked).map(r => r.name).includes(roleTokenValue);
        };
        return userRoles?.includes(roleTokenValue);
    }

//If user have CM user + IT then this will return as true. (Only IT cannot possible in MI Config)
    userHasOnlyITRole(): boolean {
        let userIT = UserRolesEnum.IT;
        let userCM = UserRolesEnum.User;
        const userAccount: Account = this.msalService.instance.getActiveAccount();
        let userRoles = userAccount?.idTokenClaims?.roles;

        let _devenv = (AppConfig["IsPreProduction"] ?? "false") === "true";
        if (_devenv && this.roleStore.isCustom && userRoles.includes("CM_Admin")) {
            let customRoles = this.roleStore.roles.filter(rr => rr.checked).map(r => r.name);
            customRoles = customRoles.filter(role=>role !== userCM);
            return customRoles.length === 1 && customRoles.includes(userIT);
        };
        userRoles = userRoles?.filter(role=>role !== userCM);
        return userRoles?.length === 1 && userRoles.includes(userIT);
    }

    userHasSubRole(roleName: string, user: User): boolean {
        if (this.gmcRoleBypass(roleName)) { return true; }

        if (user.userRoles != null) {
            const userAccount: Account = this.msalService.instance.getActiveAccount();
            const userRoles = userAccount?.idTokenClaims?.roles;
            let _devenv = (AppConfig["IsPreProduction"] ?? "false") === "true";
            if (_devenv && this.roleStore.isCustom && userRoles.includes("CM_Admin")) {
                return this.roleStore.roles.filter(rr => rr.checked).map(r => r.name).includes(roleName);
            };
            const userRoleNames = user.userRoles.map(x => x.role);
            return (userRoleNames.indexOf(roleName) > -1);
        }

        return false;
    }

    //checking if user have subrole for main APRS role or not
    async userHasSubrole(subrole: string): Promise<boolean> {
      var isSubrole = false;
      // get username for the active user from Azure
      const username = this.msalService.instance.getActiveAccount().username;
      // get user from database; only database contains subrole information
      let user = await this.getUserByCloudID(username).toPromise();
      // check if user has subroles in the database
      if (!isNullOrUndefined(user.userRoles)) {
        const userAccount: Account = this.msalService.instance.getActiveAccount();
        const userRoles = userAccount?.idTokenClaims?.roles;
        let _devenv = (AppConfig["IsPreProduction"] ?? "false") === "true";
        if (_devenv && this.roleStore.isCustom && userRoles.includes("CM_Admin")) {
            return this.roleStore.roles.filter(rr => rr.checked).map(r => r.name).includes(subrole);
        };
        // iterate through subroles and check for Sub role
        user.userRoles.forEach(userRole => {
          if (userRole.role === subrole)
          isSubrole = true;
        });
      }
      return isSubrole;
    }

    userIsCustomerAE(userCloudId: string, customer: TargetCustomer = null): boolean {
        if (!this.userIsCMAccountExec()) { return false; }

        // for now, assume we're concerned with the TargetCustomer on the app store
        if (customer == null) {
            customer = this.store.state.targetCustomer;
        }
        const salesResources = [customer.ae, customer.alternateAE, customer.aeManager];
        const result = salesResources.indexOf(userCloudId) > -1;
        // this.appInsights.logTrace(`userIsCustomerAE salesResources [${salesResources.join(',')}] includes ${userCloudId}: ${result}`);
        return result;
    }

    evalFieldsForCustomer(
        userCloudId: string,
        customerId: number,
        subscriptions: Subscription[],
        fieldMapItems: FieldMapItem[]
    ): void {
        let evalLog = `evalFieldsForCustomer ${customerId} INCOMING fieldMapItems: ${JSON.stringify(fieldMapItems)}`;
        try {
            this.store.updateComponentFieldMap(null); // start fresh

            const setUserSubRoles = (u: CurrentUser): UserRole[] => {
                // dirty way to check for sub-role, REVISIT...
                return u != null && u.roles != null ? u.roles.filter(r => r.role.indexOf('_') === -1) : [];
            };

            let fieldMasterLookup: FieldMaster[] = null;
            let businessUnitsLookup: BusinessUnit[] = null;
            let customer = this.store.state.targetCustomer;
            let fetchedCustomerRpf;
            let user = this.store.state.currentUser;
            const userSubRoles = setUserSubRoles(user);
            let fetchedUserRpfIds = null;

            const userHasUnitRole = (roleName: string): boolean => {
                if (this.gmcRoleBypass(roleName)) { return true; }

                return this.userHasRole(roleName) || userSubRoles.find(r => r.role === roleName) != null;
            };
            const userHasCustomerRpf = (): boolean => {
                let userRpfIds = user.userRpfIds;

                const gmc = this.cookieService.getGodModeCookie();
                evalLog += `\nGOD MODE cookie (in UserService userHasCustomerRpf): ${JSON.stringify(gmc)}`;
                if (gmc != null) { userRpfIds = gmc.hasRpfIds; }

                // eslint-disable-next-line max-len
                evalLog += `\nuserHasCustomerRpf(${customer.homeRpf}).. userRpfIds: ${(userRpfIds == null ? null : userRpfIds.join(','))}`;
                if (userRpfIds != null && userRpfIds.indexOf(customer.homeRpf) > -1) {
                    return true;
                }
                return false;
            };

            if (user == null || typeof (user.userRpfIds) === 'undefined') {
                // console.log('app store user is null, or missing userRpfIds, fetch it and recursively try again...');
                subscriptions.push(this.getUserByCloudID(userCloudId)
                    .subscribe(u => {
                        // console.log('getUserByCloudID result...');
                        subscriptions.push(this.getUserRpfsByUserId(u.id)
                            .subscribe(userRpfs => {
                                fetchedUserRpfIds = userRpfs.map(x => x.rpfIdentificationNumber);
                                this.store.updateCurrentUser({
                                    id: u.id,
                                    name: `${u.firstName} ${u.lastName}`,
                                    roles: u.userRoles,
                                    userRpfIds: fetchedUserRpfIds
                                });
                                user = this.store.state.currentUser;
                                // console.log('calling evalFieldsForCustomer post-user fetch...');
                                this.evalFieldsForCustomer(userCloudId, customerId, subscriptions, fieldMapItems);
                                return;
                            })
                        );
                    })
                );
                return;
            } else if (customer == null || (customer.homeRpf == null && typeof (fetchedCustomerRpf) === 'undefined')) {
                // console.log('app store customer is null, or missing homeRpf, fetch it and recursively try again...');
                subscriptions.push(this.customerService.getCustomerProfile(customerId)
                    .subscribe(c => {
                        // console.log('getCustomerProfile result...');
                        fetchedCustomerRpf = c.customerRPF != null ? c.customerRPF.rpfIdentificationNumber : null;
                        this.store.updateTargetCustomer({
                            id: c.id,
                            name: c.customerName,
                            status: c.customerStatusSystemNumber,
                            shipperType: c.shipperTypeSystemNumber,
                            ae: c.ae,
                            alternateAE: c.alternateAE,
                            aeManager: c.aeManager,
                            homeRpf: fetchedCustomerRpf
                        });
                        customer = this.store.state.targetCustomer;
                        // console.log('calling evalFieldsForCustomer post-customer fetch...');
                        this.evalFieldsForCustomer(userCloudId, customerId, subscriptions, fieldMapItems);
                        return;
                    })
                );
                return;
            } else {
                // console.log('app store user and customer are available, happily continue...');

                subscriptions.push(this.lookUpService.getBusinessUnits()
                    .subscribe(bu => {
                        businessUnitsLookup = bu;

                        subscriptions.push(this.lookUpService.getFieldMaster()
                            .subscribe(fm => {
                                fieldMasterLookup = fm;

                                fieldMapItems.forEach(fmi => {
                                    // console.log('.fmi: ', fmi);

                                    const fmFilter = fieldMasterLookup
                                                            .filter(x => x.fieldName.toUpperCase() === fmi.fieldName.toUpperCase())
                                    if (fmFilter.length > 0) {
                                        fmi.editableBy = businessUnitsLookup
                                                            .filter(x => fmFilter[0].editConstraint.includes(x.id))
                                                            .map(y => y.roleName);
                                    } else {
                                        fmi.editableBy = null;
                                    }

                                    let lookupItems =
                                        fieldMasterLookup.filter(x => x.fieldName.toUpperCase() === fmi.fieldName.toUpperCase());
                                    // console.log('..matching lookupItems: ', lookupItems);
                                    if (lookupItems.length === 0) {
                                        // default to editability by AE (and Sales Support) if unspecified
                                        fmi.editable = (this.userIsCustomerAE(userCloudId, customer) || this.userIsCMSalesSupport());
                                        // eslint-disable-next-line max-len
                                        evalLog += `\n${fmi.fieldName} not explicitly in FM editable per default Cust AE/Sales Support permission: ${fmi.editable}`;
                                        return;
                                    }

                                    // handle a case where fieldName (or even fieldTableName) isn't unique enough,
                                    // as with uploadables or customer addresses
                                    if (lookupItems.length > 1 && fmi.fieldTableName != null) {
                                        lookupItems = fieldMasterLookup.filter(
                                            x => x.fieldTableName.toUpperCase() === fmi.fieldTableName.toUpperCase());
                                        if (lookupItems.length > 1 && fmi.subFieldName != null && fmi.subFieldValue != null) {
                                            lookupItems = fieldMasterLookup.filter(
                                                x => x.subFieldName === fmi.subFieldName && x.subFieldValue === fmi.subFieldValue);
                                        }
                                    }

                                    const lookupItem = lookupItems[0];
                                    // console.log('..distilled lookupItem: ', lookupItem);
                                    // NOTE - shipper type is required for Active, but its FM requiredShipperTypes value will be null,
                                    // as it's otherwise a circular reference, so make a special accommodation for it
                                    fmi.requiredForActive = fmi.key === 'shipperType' ||
                                        (lookupItem.requiredShipperTypes != null &&
                                            lookupItem.requiredShipperTypes.length > 0 &&
                                            lookupItem.requiredShipperTypes.indexOf(customer.shipperType) > -1);

                                    fmi.multiInstance = lookupItem.multiInstance;
                                    fmi.approvable = lookupItem.requireApproval;
                                    if (lookupItem.requireApproval) {
                                        const approvableUnit =
                                            businessUnitsLookup.filter(x => lookupItem.approvalConstraint.indexOf(x.id) > -1);
                                        fmi.approvableBy = approvableUnit.map(function (a) { return a.roleName; }).join(', ');
                                    }

                                    fmi.editable = false;
                                    const fieldEditorRoles = lookupItem.editConstraint;
                                    const allRoleUnits = businessUnitsLookup.filter(x => fieldEditorRoles.indexOf(x.id) > -1);
                                    // gather and sort assoc'd business units for the field simple to more complex so that
                                    // if the user has one of the simpler roles, we don't have to do more cross-checking than necessary
                                    const simpleRoleUnits = allRoleUnits.filter(x => !x.rpfConstrained &&
                                        x.roleName !== UserRolesEnum.AccountExec);
                                    const rpfConstrainedRoleUnits = allRoleUnits.filter(x => x.rpfConstrained);
                                    const aeRoleUnit = allRoleUnits.find(x => x.roleName === UserRolesEnum.AccountExec);
                                    for (const unit of simpleRoleUnits) {
                                        if (userHasUnitRole(unit.roleName)) {
                                            fmi.editable = true;
                                            // eslint-disable-next-line max-len
                                            evalLog += `\n${fmi.fieldName} editable per simple role (${unit.roleName}) permission: ${fmi.editable}`;
                                            return;
                                        }
                                    }

                                    if (aeRoleUnit != null && this.userIsCustomerAE(userCloudId, customer)) {
                                        fmi.editable = true;
                                        evalLog += `\n${fmi.fieldName} editable per userIsCustomerAE: ${fmi.editable}`;
                                        return;
                                    }

                                    for (const unit of rpfConstrainedRoleUnits) {
                                        if (userHasUnitRole(unit.roleName) && userHasCustomerRpf()) {
                                            fmi.editable = true;
                                            // eslint-disable-next-line max-len
                                            evalLog += `\n${fmi.fieldName} editable per user has role (${unit.roleName}) & Cust RPF permission: ${fmi.editable}`;
                                            return;
                                        }
                                    }

                                    // console.log('..fieldMapItem: ', fmi);
                                });

                                evalLog += `\n...STORING fieldMapItems (post-eval loop): ${JSON.stringify(fieldMapItems)}`;
                                this.appInsights.logTrace(evalLog);
                                this.store.updateComponentFieldMap(fieldMapItems);
                            })
                        );
                    })
                );
            }
        } catch (error) {
            this.appInsights.logTrace(`evalFieldsForCustomer CATCH: ${JSON.stringify(error)} \n ${evalLog}`);
        }
        // a finally block is potentially misleading here as catch-all for evalLog bc it would execute before the subscriptions return
    }

    gmcRoleBypass(roleName: string): boolean {
        const gmc = this.cookieService.getGodModeCookie();
        // console.log('GOD MODE cookie (in UserService.gmcRoleBypass): ', gmc);
        if (gmc != null) {
            if (gmc.hasAllRoles) {
                this.appInsights.logTrace('gmcRoleBypass returning true for hasAllRoles');
                return true;
            }
            if (this.cookieService.getGodModePermission(roleName)) {
                this.appInsights.logTrace('gmcRoleBypass returning true for role-specific permission');
                return true;
            }
        }

        return false;
    }

    userAccessDenied() {
        this.router.navigate([HttpErrorCode.Unauthorized.toString()]);
    }

    getAuthUserProfile(): Observable<AuthenticatedUser> {
        const userAccount: Account = this.msalService.instance.getActiveAccount();
        // console.log(userAccount);
        this.loggedInUser.next({ userName: userAccount?.name, cloudId: userAccount?.username })
        return this.loggedInUser.asObservable();
    }
}
