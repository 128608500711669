import { Component, OnInit, OnDestroy } from '@angular/core';
import { ComponentBase } from '../models/component-base';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { LookUpService } from '../services/look-up.service';
import { UserService } from '../services/user.service';
import { CmAppStore } from '../services/cm-app-store';
import { User } from '../models/user.model';
import { NotificationRpf, UserRpf } from '../models/user-rpf.model';
import { RPF } from '../models/rpf.model';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html'
})

export class NotificationSettingsComponent extends ComponentBase implements OnInit, OnDestroy {
  allRpfs: RPF[];
  userRpfs: UserRpf[];
  notificationRpfs: NotificationRpf[];
  user: User;
  allSelected = false;

  constructor(
    msalService: MsalService,
    broadcastService: MsalBroadcastService,
    activatedRoute: ActivatedRoute,
    private lookupService: LookUpService,
    private userService: UserService,
    private router: Router,
    private store: CmAppStore
  ) {
    super(msalService, broadcastService, activatedRoute);

    this.userRpfs = [];
  }

  ngOnInit() {
    super.ngOnInit();

    this.subscriptions.push(this.userService.getUserByCloudID(this.modifiedByCloudId)
      .subscribe(user => {
        this.user = user;
        this.getUserRPFData();
      })
    );

  }

  getUserRPFData(): void {
    let allRpfsLoaded = false;
    let userRpfsLoaded = false;

    const checkLoadedToProceed = () => {
      if (allRpfsLoaded && userRpfsLoaded) {
        this.populateNotificationRpfs();
      }
    };

    this.subscriptions.push(this.lookupService.getRPF()
      .subscribe(rpfsResponse => {
        //NOTE - this logic filtering logic should always align to corresponding logic in user-rpf.component.ts
        this.allRpfs = this.lookupService.filterSupportedRPFs(rpfsResponse);
        allRpfsLoaded = true;
        checkLoadedToProceed();
      })
    );

    this.subscriptions.push(this.userService.getUserRpfsByUserId(this.user.id)
      .subscribe(userRpfsResponse => {
        this.userRpfs = userRpfsResponse;
        userRpfsLoaded = true;
        checkLoadedToProceed();
      })
    );
  }

  populateNotificationRpfs(): void {
    this.notificationRpfs = [];
    this.allRpfs.forEach(rpf => {
      const userRpf = this.userRpfs.find(x => x.rpfIdentificationNumber === rpf.id && x.isActive === true);
      this.notificationRpfs.push({
        rpfIdentificationNumber: rpf.id,
        rpfName: rpf.rpfName,
        permissionsEnabled: userRpf != null,
        notifyEnabled: userRpf != null && userRpf.notify === true
      });
    });

    this.loading = false;
    this.evalAllSelected();
  }

  evalAllSelected(): void {
    this.allSelected = this.notificationRpfs.filter(x => x.notifyEnabled).length === this.notificationRpfs.length;
  }

  selectAll(event: any): void {
    this.allSelected = event.target.checked;
    this.notificationRpfs.filter(x => x.permissionsEnabled).forEach(x => x.notifyEnabled = this.allSelected);
  }

  selectRpf(rpf: RPF): void {
    this.evalAllSelected();
  }

  clearAll(): void {
    this.notificationRpfs.filter(x => x.permissionsEnabled).forEach(x => x.notifyEnabled = false);
    this.evalAllSelected();
  }

  saveNotificationSettings(): void {
    this.store.showLoadIndicator();
    this.userRpfs.forEach(userRpf => {
      const notificationRpf = this.notificationRpfs.find(x => x.rpfIdentificationNumber === userRpf.rpfIdentificationNumber);
      userRpf.notify = notificationRpf.notifyEnabled;
      userRpf = this.attachAuditProperties(userRpf);
    });

    this.subscriptions.push(this.userService.putUserNotificationsByRpf(this.userRpfs)
      .subscribe(() => {
        this.store.clearLoadIndicator();
        this.store.showSuccessMessage('Notification Preferences Saved Successfully!');
        this.getUserRPFData();
      })
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
