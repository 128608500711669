// values for ShipperType used on Transportation components
export enum ShipperType {
    DomesticOutbound = 1,
    DomesticOutboundORMD = 2,
    DomesticOutboundGround = 4,
    International = 5,
    InternationalWWE = 6,
    MiReturns = 7,
    ParcelReturnsService = 8,
}

// used to umbrella the finder-grained ShipperTypes, eg the 3 types of DomesticOutbound...
export type ShipperTypeCategory = 'domestic' | 'returns' | 'international' | 'internationalWWE';
