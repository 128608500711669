<div id="cm-notification-settings-styles">
  <div id="row1">
    <section id="column2">
      <div class="container">
        <div class="Name-Col shadow">
          <h5>Notification Settings</h5>
        </div>

        <div class="form-content shadow">
          <ngb-alert *ngIf="warningMessage" type="warning" (close)="warningMessage = null" class="warning-alert">
            {{ warningMessage }}</ngb-alert>

          <div class="row">
            <div *ngIf="loading; else elseBlock" class="loader-cnt container">
              <div class="loader"></div>
            </div>

            <ng-template #elseBlock>
              <h5 *ngIf="!loading" class="col-12 description">Select the RPFs for which you would like to receive
                Customer change notifications</h5>

              <div class="checkbox-cnt col-12 col-sm-6 col-lg-3">
                <div class="normalized pretty p-icon p-smooth p-plain">
                  <input type="checkbox" id="checkbox_all" [(ngModel)]="allSelected" name="all_checked"
                    (click)="selectAll($event)" />
                  <div class="state p-success">
                    <i class="icon fas fa-check"></i>
                    <label>Select/Deselect All</label>
                  </div>
                </div>
              </div>

              <div class="checkbox-cnt col-12 col-sm-6 col-lg-3" *ngFor="let rpf of notificationRpfs; let i = index">
                <div class="normalized pretty p-icon p-smooth p-plain" [ngbTooltip]="rpfNotPermittedTip"
                  triggers="click:blur" placement="bottom" tooltipClass="" [disableTooltip]="rpf.permissionsEnabled">
                  <ng-template #rpfNotPermittedTip>You do not have access to this RPF.<br>Please contact the Admin to
                    enable.</ng-template>
                  <input type="checkbox" id="checkbox{{i}}" [(ngModel)]="rpf.notifyEnabled" name="{{i}}checked"
                    [disabled]="!rpf.permissionsEnabled" (click)="selectRpf(rpf)" />
                  <div class="state p-success" [class.disabled]="!rpf.permissionsEnabled">
                    <i class="icon fas fa-check"></i>
                    <label>{{rpf.rpfName}}</label>
                  </div>
                </div>
              </div>
              <div id="btn-div" class="col-12">
                <button class="btn btn-custom-submit" type="submit" (click)="saveNotificationSettings()"
                  [disabled]="userRpfs.length === 0">
                  Save &nbsp;<i class="fas fa-arrow-circle-right"></i>
                </button>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>